import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../shared/modal/modal.component';
import { Socket } from 'ngx-socket-io';
import { socketService } from '../../../services/socket.service';

@Component({
  selector: 'app-update-timeline',
  templateUrl: './update-timeline.component.html',
  styleUrls: ['./update-timeline.component.css'],
})
export class UpdateTimelineComponent implements OnInit {
  @ViewChild('resolveFile') resolveFile: ElementRef;
  @ViewChild('messageModal') messageModal: ModalComponent;
  activeRoute$: Subscription;
  timelineSocket$: Subscription;
  fileUpload$: Subscription;
  updateTimeSave$: Subscription;
  getLis$: Subscription;
  validateToken$: Subscription;
  submitComment$: Subscription;

  showProgress: boolean = false;

  user_name: string = '';
  tokenUser: string = '';
  ticketcurrentStatus: any;
  recordList = [];

  maxChars: number = 300;

  updateTimeForm: FormGroup;
  uploadedObject = {};
  token: any;
  isChangeTicket:boolean = false;
  ticketInfo:any;
  planningFormData:any[]=[];
  scheduleFormData:any[]=[];
  RiskFormData:any[]=[];
  Priority:any[]=[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private socket: Socket,
    private apiService: ApiService,
    private socketService: socketService
  ) {
    this.updateTimeForm = new FormGroup({
      user_email: new FormControl('', [Validators.required]),
      user_id: new FormControl('', [Validators.required]),
      ticketId: new FormControl('', [Validators.required]),
      comments: new FormControl('', [Validators.required]),
      attachment: new FormControl(null, []),
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params.token;
      this.isChangeTicket = params?.changeTicket ?  params?.changeTicket : false
    });
  }

  async ngOnInit() {
    // await this.getList();
    this.getTokenDetails();
    this.getPriority()
  }

  openAttachment(file) {
    window.open(`${environment.api_preview_url}${file}`);
  }

  /***********Service */

  onFileChange(fileInput) {
    const file = fileInput.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    this.apiService.postFile('tickets', formData).subscribe((res) => {
      if (!res.status) return;

      this.uploadedObject = {
        id: res.files.id,
        file: res.files[0],
        fileName: file.name,
      };
    });
  }

  submitComment() {
    let data = {
      comment: this.updateTimeForm.value.comments,
      token: this.token,
      attachment: this.uploadedObject['id'] ? this.uploadedObject['id'] : null,
    };
    if (!data.comment) {
      this.messageModal.warnDialog(
        'Please add your comments before submitting'
      );
      return;
    }
    this.submitComment$ = this.apiService
      .post('update-customer-timeline', data)
      .subscribe((e) => {
        if (e.status) {
          this.uploadedObject = {};
          this.updateTimeForm.reset();
          this.resolveFile.nativeElement.value = '';

          this.getTokenDetails();
        } else {
          this.messageModal.errorDialog(e.message);
        }
      });
  }

  getTokenDetails() {
    let data = {
      token: this.token,
    };
    this.validateToken$ = this.apiService
      .post('validate-ticket-and-get-timeline', data)
      .subscribe((e) => {
        if (e.status) {
          this.recordList = e.data;
          this.user_name = e.customerInfo.customerName;
          this.ticketcurrentStatus =
            this.recordList[0].ticket.currentStatus.status.status != 'Closed'
              ? true
              : false;
          this.socket.ioSocket.io.disconnect();
          this.socket.ioSocket.io.opts.query = {
            user_token: this.token,
            userType: 'Customer',
          };
          this.socket.ioSocket.io.connect();
          this.initiateSocket(e['ticket']['ticketId']);
          this.updateTimeForm.get('ticketId').setValue(e.ticket['ticketId']);
          this.ticketInfo = e['ticket']
          this.getChangeManagementTabsInfo()
        } else {
          return false;
        }
      });
  }

  //Initiate Socket
  initiateSocket(ticketId) {
    this.timelineSocket$ = this.socketService
      .getMessage(`${ticketId}_LISTEN_TIMELINE_EMAIL`)
      .subscribe((msg) => {
        this.recordList = msg.value;
      });
  }

  submit() {
    if (this.updateTimeForm.invalid) return;

    this.showProgress = true;
    // this.apiService._post('api/appointment/customer-timeline-update', this.updateTimeForm.value).subscribe(res => {
    //   this.showProgress = false;
    //   if (!res.success) return this.modalService.errorMessage(res['message']).pipe(take(1));

    //   this.updateTimeForm.reset();
    //   this.updateTimeForm.updateValueAndValidity();
    //   this.updateFormFieldFromRoute();

    //   this.getList();
    //   this.modalService.successMessage(res['message']).pipe(take(1));

    // });
  }

  // async getList() {
  //   this.recordList = []
  // }

  getPriority() {
    this.apiService.get('ticket-sla/get-all').subscribe((response) => {
      this.Priority = response.data?.map((item) => ({
        name: item?.priority,
        value: item?.id,
      }));
    });
  }

  getChangeManagementTabsInfo() {
    console.log('this.this.ticketInfo[0]',this.ticketInfo);
    this.apiService.get('chg-mngmnt/get-detail?ticketId=' + this.ticketInfo?.id).subscribe(
      (result) => {
        if (result?.data) {
          console.log('result?.data-------', result?.data);
          let responseData = result?.data;
          let indata: any[] = []
          let priorityFound
          if (responseData?.priority && this.Priority?.length) {
            priorityFound = this.Priority.find(item => item.value == responseData?.priority?.id)
          }
          if (this.planningFormData.length == 0) {
            this.planningFormData.push(
              { label: 'Change Work Notes', value: responseData?.work_notes },
              { label: 'Implementation Plan', value: responseData?.imp_plan },
              { label: 'Risk and Impact Analysis', value: responseData?.risk_and_impact_analysis },
              { label: 'Backout Plan', value: responseData?.backout_plan },
              { label: 'Test Plan', value: responseData?.test_plan });
          }
          if (this.scheduleFormData.length == 0) {
            this.scheduleFormData.push(
              { label: 'Planned Start Date', value: responseData?.planned_start_date },
              { label: 'Planned End Date', value: responseData?.planned_end_date },
              { label: 'Priority', value: priorityFound?.name },
              { label: 'Requested by Date', value: responseData?.request_by_date },)
          }
          if (this.RiskFormData.length == 0) {
            this.RiskFormData.push(
              { label: 'Outage Scope / Complexity', value: responseData?.complexity },
              { label: 'Users Impacted', value: responseData?.users_impacted },
              { label: 'Business Impacted of Change', value: responseData?.business_impacted },
              { label: 'Backout Prior to End of Change', value: responseData?.backout_effort },
              { label: 'Experience of Implementing the Change', value: responseData?.experience_in_impl });
          }
 
        }
      },
      (error) => {
        console.error('Error on getting Change management tab details');
      }
    );
  }


  ngOnDestroy(): void {
    if (this.validateToken$) this.validateToken$.unsubscribe();
    if (this.submitComment$) this.submitComment$.unsubscribe();
  }
}
