
<div class="p-grid user-container" id="licenseFormContainer">
  <div class="p-col-12">
      <panel [panelConfig]="panelConfig">
          <div panelHeader>
              <span>{{tableData}}</span>
          </div>
          <div panelBody id="inner_wrapper">
              <p-tabView>
                  <div class="p-mb-3" style="border-top: 1px solid rgb(190 192 193) !important;">
                  </div>
                  <p-tabPanel header="License Information" id="tabpanel">
                      <ng-content select="#assetForm"></ng-content>
                  </p-tabPanel>
                  <p-tabPanel header="Documents" id="tabpanel">
                  <app-document-upload></app-document-upload>
                  </p-tabPanel>
              </p-tabView>
          </div>
      </panel>
  </div>
</div>
<message-modal #messageModal></message-modal>
