import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IDataTable } from 'src/app/models/modules/shared/data-table.model';
import { documentUploadServices } from 'src/app/services/document-upload.service';
import { UserService } from '../../../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssetManagementMasterApiService } from '../../../services/asset-management-master-api.service';
import * as moment from 'moment';
import { ModalComponent } from '../modal/modal.component';

export interface IDocumentType {
  Id: number;
  name: string;
  description: string;
  label: string;
  status: Status;
  created_date: any;
  update_date: any;
  created_by: any;
  updated_by: any;
  orderno: any;
}

export interface Status {
  type: string;
  data: number[];
}

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css'],
})
export class DocumentUploadComponent implements OnInit, OnChanges {
  @Input() moduleName: string;
  @Input() parentId: number;
  @Input() assetId: number;
  @ViewChild('messageModal') messageModal: ModalComponent;

  closeDialog: any;

  dataTable: IDataTable = {
    columns: [
      {
        field: 'assetId',
        header: 'Asset ID',
      },
      {
        field: 'fileName',
        header: 'Document Name',
      },
      {
        field: 'fileType',
        header: 'File Type',
      },
      {
        field: 'createdAt',
        header: 'Date',
        type: 'date',
      },
    ],
    data: [],
    scrollHeight: '20',
    rows: 5,
    totalRecords: 10,
    addButton: false,
    editButton: false,
    viewButton: false,
    deleteButton: true,
    kbsdeleteBtn: false,
    downloadButton: true,
    csvExcelPdfButton: false,
  };

  selectedFile: string;
  documentTypeList: IDocumentType[];
  selectedDocumentType: IDocumentType;
  documentData = {};
  documentUploadForm: FormGroup;
  docId: number;

  constructor(
    private documentUploadServices: documentUploadServices,
    private AssetManagementMaster: AssetManagementMasterApiService,
    private fb: FormBuilder
  ) {
    this.onDocumentUploadForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getDocumentList();
  }

  ngOnInit(): void {
    this.getDocumentList();
  }

  onDocumentUploadForm() {
    this.documentUploadForm = this.fb.group({
      documentType: ['', Validators.required],
      documentName: ['', Validators.required],
    });
    this.getDocumentType();
  }

  getDocumentType() {
    // this.AssetManagementMaster.documentType().subscribe((data) => {
    //   this.documentTypeList = data.data;
    //   this.documentUploadForm
    //     .get('documentType')
    //     .patchValue(this.documentTypeList);
    // });
    this.AssetManagementMaster.documentType().subscribe((data) => {
      if (data) {
        let modifiedData = data?.data?.map((item) => ({
          label: item?.name,
          value: item?.name,
          status: item?.status?.data[0],
        }));
        this.documentTypeList = modifiedData
          .filter((item) => item.status === 1)
          .sort((a, b) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
      }
    });
  }

  passDataToForm(value: any) {
    if (value?.type == 'delete') {
      this.moduleName = value?.data?.moduleName;
      this.parentId = value?.data?.parentId;
      this.assetId = value?.data?.assetId;
      this.docId = value?.data?.docId;
      this.deleteDocument();
    }
  }

  onGetDocumentData(fileDetails: any) {
    this.selectedFile = fileDetails.files.filename;

    let document = {
      documentName: this.documentUploadForm.value.documentName,
      documentType: this.documentUploadForm.value.documentType.label,
      fileName: fileDetails.files.filename,
      fileType: fileDetails.files.mimeType,
      fileSize: fileDetails.files.size,
      description: '',
      tags: fileDetails.files.id,
      documentStatus: 1,
      fileLocation: fileDetails.files.url,
      module: this.moduleName,
      editedById: localStorage.getItem('user_id'),
      parentId: this.parentId,
      assetId: this.assetId,
      docId: 0,
      documentVersion: '',
      documentOwner: '',
      documentExpiryDate: '',
    };

    this.documentData = document;
  }

  uploadDocumentData() {
    this.documentData['documentName'] =
      this.documentUploadForm.value?.documentName;
    this.documentData['documentType'] =
      this.documentUploadForm.value?.documentType?.label;

    this.documentUploadServices.onDocumentUpload(this.documentData).subscribe({
      next: (data) => {
        this.documentUploadForm.reset();
        this.messageModal.successDialog(data.message);
        this.selectedFile = '';
      },
      complete: () => {
        this.getDocumentList();
      },
    });
  }

  getDocumentList() {
    this.documentUploadServices
      .getDocumentListBy(this.moduleName, this.parentId, this.assetId)
      .subscribe(
        ({ data }) => {
          const tableDataList = data.map(
            ({
              Id,
              asset_id,
              document_name,
              file_type,
              created_date,
              file_location,
              module,
              parent_id,
            }) => {
              return {
                docId: Id,
                assetId: asset_id,
                fileName: document_name,
                fileType: file_type,
                createdAt: created_date,
                fileLocation: file_location,
                moduleName: module,
                parentId: parent_id,
              };
            }
          );
          this.dataTable = {
            ...this.dataTable,
            data: tableDataList,
          };
        },
        (error) => {
          this.dataTable = {
            ...this.dataTable,
            data: [],
          };
        }
      );
  }

  deleteDocument() {
    const queryParams = {
      docId: this.docId,
      editedById: localStorage.getItem('user_id'),
      assetId: this.assetId,
      documentStatus: 0,
    };
    this.documentUploadServices.deleteDocument(queryParams).subscribe({
      next: (data) => {
        this.messageModal.successDialog(data.message);
      },
      complete: () => {
        this.getDocumentList();
      },
      error: (error) => {
        this.messageModal.errorDialog(error.error.message);
      },
    });
  }
}
