import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { IUser } from '../models/services/user-service.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiError = new BehaviorSubject('');
  errormessage = this.apiError.asObservable();

  userPermissions = {
    announcement: true,
    approve: false,
    assetManagement: true,
    assetMaster: false,
    configuration: false,
    contactInfo: false,
    department: false,
    extendSla: false,
    faq: false,
    knowledgebase: true,
    organization: false,
    reports: false,
    role: false,
    serviceType: false,
    slaEvent: false,
    slaModel: true,
    supportGroup: false,
    supportLevel: false,
    task: true,
    ticket: true,
    user: false,
  };
  getSession() {
    let _session = localStorage.getItem('Session');
    _session = _session ? JSON.parse(atob(_session)) : '';
    return _session;
  }

  updateSession(session) {
    let _session = session;
    _session['session_time'] = Date();

    _session = btoa(JSON.stringify(_session));
    localStorage.setItem('Session', _session);
  }

  // update user session time
  updateSessionTime() {
    let _session = this.getSession();
    _session['session_time'] = Date();

    let _updatedSession = btoa(JSON.stringify(_session));

    localStorage.setItem('Session', _updatedSession);
  }

  // destroy Session
  destroySession() {
    localStorage.removeItem('Session');
  }

  // validate user session
  validateSession(): boolean {
    // get current and logged/updated session
    const _currentDate = new Date();

    const _session = this.getSession();
    const _sessionDate = new Date(_session['session_time']);

    // calc time difference in hours
    const _sessionDiff =
      Math.abs(_currentDate.getTime() - _sessionDate.getTime()) / 36e5;

    // validate with session timeout and return result
    const _sessionValid =
      _sessionDiff <= environment.session_time_limit ? true : false;
    return _sessionValid;
  }

  statusHandle(statusId: number) {
    const statusList = [
      { id: 1, status: 'Active' },
      { id: 0, status: 'Inactive' },
      { id: 2, status: 'Resolved' },
      { id: 4, status: 'Deleted' },
      { id: 3, status: 'Hold' },
    ];
    return statusList.find((x) => x.id === statusId);
  }

  getUserAccess() {
    return this.userPermissions;
  }

  setApiError(err: any) {
    this.apiError.next(err);
  }
}
