<p-card class="p-card-bg">
    <div class="p-grid p-align-center vertical-container">
        <div class="p-col-2 p-md-2 pageTitle"><b>Feedback <span *ngIf="selectedTicket">/ <a class="cursor-pointer"
                        (click)="navigate(selectedTicket)">{{selectedTicket?.ticketName}}</a></span></b></div>
        <div class="p-col-5 p-md-5 p-pb-1">
            <div class="date-control-wrapper">
                <div class="p-d-flex p-ai-center">
                    <label class="labls">From</label>
                    <p-calendar id="calanderIcon" [showIcon]="true" [(ngModel)]="fromDate" [maxDate]="today"
                        [inputStyle]="{'width':'75%'}"></p-calendar>
                </div>
            </div>
            <div class="date-control-wrapper p-ml-2">
                <div class="p-d-flex p-ai-center">
                    <label class="labls">To</label>
                    <p-calendar id="calanderIcon" [showIcon]="true" [minDate]="fromDate" [maxDate]="today"
                        [(ngModel)]="toDate" [inputStyle]="{'width':'75%'}">
                    </p-calendar>
                </div>

            </div>
            <div class="display-inline-block p-ml-2">
                <button pButton type="button" (click)="getFeedbacks()" iconPos="left"
                    label="Apply Date Filter"></button>
            </div>

        </div>
        <div class="p-col-3 p-md-3 p-pb-1">
            <h3 class="labls"> Selected date range:</h3>
            {{fromDate | date: 'EEE MMM dd yyyy'}} -
            {{toDate| date: 'EEE MMM dd yyyy'}}
        </div>
        <div class="p-col-2 p-md-2 p-pb-1 p-pt-1">
            <span class="search-container">
                <input id="search-ticket" type="text" pInputText title="Search by Ticket ID, Email"
                    placeholder="Search by Ticket ID, Email" (input)="SearchTicketInfo()" [(ngModel)]="ticketInfoSearch"
                    class="search-input" /> &nbsp;
            </span>
        </div>
    </div>

</p-card>
<div class="p-grid p-mt-2">
    <div class="p-col-3">
        <div class="tickets-list">
            <ng-container *ngIf="ticketDetailsArray.length">
                <div class="ticket-info-list" [class.active-list-item]="i == selectedListItem"
                    *ngFor="let ticket of ticketDetailsArray; let i = index" (click)="onTicketInfoSelect(ticket,i)">
                    <b [title]="ticket?.ticketName"><small>{{ticket.ticketName}} </small></b> <br>
                    <span [title]="ticket.agent +' - '+ ticket.emailId"> {{ticket.agent}} - {{ticket.emailId}} </span>
                </div>
            </ng-container>
            <ng-container *ngIf="ticketDetailsArray.length <= 0">
                <h4 class="p-d-flex p-jc-center">No Data Found</h4>
            </ng-container>

        </div>

    </div>
    <div class="p-col-9">
        <div class="ticket-questions-view">
            <ng-container *ngIf="feedbacks.length">
                <div *ngFor="let feedback of feedbacks" class="feedback-panel">
                    <p-panel>
                        <p-header>
                            <div class="ui-helper-clearfix">
                                <span class="ui-panel-title">{{feedback.question}}</span>

                            </div>
                        </p-header>
                        {{feedback.answer}}
                        <div class="p-pt-2" *ngIf="feedback.reason != ''">
                            <b>Reason : </b> {{feedback.reason}}  
                        </div>
                    </p-panel>
                </div>

            </ng-container>
            <ng-container *ngIf="feedbacks.length <= 0">
                <h4 class="p-d-flex p-jc-center">No Data Found</h4>
            </ng-container>


        </div>
    </div>
</div>