export const environment = {
  production: true,
  api_url_upload:
    'https://api-helpdesk.aerodyne.group/uploads/',
  api_preview_url: 'https://api-helpdesk.aerodyne.group/uploads/public',
  api_url: 'https://api-helpdesk.aerodyne.group/',
  api_url_file: 'https://api-helpdesk.aerodyne.group/',
  socket_url: 'https://api-helpdesk.aerodyne.group',


  api_img_url: 'https://api.tanya.wait-wise.com',
  payment_url: 'http://172.20.8.210:4002',
  ui_base_url: 'http://172.20.8.216:4200/',
  session_time_limit: 20,
  appointment_base_url: 'http://172.20.8.210:4300/',
  appointment_api_url: 'http://172.20.8.210:8081/',
  hash_url: 'http://172.20.8.210:4000/api/',
  vicidial_url: 'https://172.16.16.12/agc/helpdesk/vicidial.php',
  vicidial_api_url: 'https://172.16.16.12/agc/api.php',
  shorten_url_live: 'https://url.wait-wise.com/',
  shorten_url_local: 'http://172.20.8.210:5000/',
  calendar_url: 'http://localhost:4200/',
  client: 'aero',
  file_size:5
};

