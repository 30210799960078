import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';

// interface IPriceData {
//       id: 31,
//       ticket_id: '1060',
//       rzr_customer_id: 'cust_F6sDsclP1IqpKr',
//       rzr_invoice_id: 'inv_F6tS35mUMuJPJ5',
//       rzr_order_id: 'order_F6tS37ktaHGAcM',
//       expire_at: null,
//       issue_at: null,
//       paid_at: null,
//       amount: null,
//       cancelled_at: null,
//       description: 'Test',
//       short_url: 'https://rzp.io/i/quu2RJn',
//       payment_status: null,
//       invoice_receipt: null,
//       rzr_signature: null,
//       created_at: '2020-06-25T10:40:20.963Z',
//       tax_description: 't',
//       tax_amount: '1.00',
//       notes: '',
//       discount: '200.00',
//       rzr_payment_id: null,
//       invoice_url: 'https://doc.wait-wise.com/helpdesk/payment?type=CUSTOMER&invoice=inv_F6tS35mUMuJPJ5',
//       billed_amount: '801.00',
//       price: '1000.00',
//       invoice_no: 'inv_F6tS35mUMuJPJ5'

// }

enum EType {
  'USER' = 'USER',
  'CUSTOMER' = 'CUSTOMER',
}

@Component({
  selector: 'app-view-payment',
  templateUrl: './view-payment.component.html',
  styleUrls: ['./view-payment.component.css'],
})
export class ViewPaymentComponent implements OnInit {
  activeRoute$: Subscription;
  fetchData$: Subscription;
  createPayment$: Subscription;

  priceFormGroup: FormGroup;
  pricedData = {
    id: null,
    ticket_id: '',
    rzrCustomerId: '',
    rzrInvoiceId: '',
    rzrOrderId: '',
    expireAt: null,
    issueAt: null,
    paidAt: null,
    amount: null,
    cancelledAt: null,
    description: '',
    shortUrl: '',
    status: null,
    invoiceReceipt: null,
    rzrSignature: null,
    created_at: '',
    taxDescription: '',
    taxAmount: '',
    notes: '',
    discount: '',
    rzrPaymentId: null,
    invoiceUrl: '',
    billedAmount: '',
    price: '',
    invoice_no: '',
    ticket: null,
  };

  showSubmitButton: boolean = true;
  showProgress: boolean = false;

  max_note_char: number = 15;

  type: EType;
  refType = EType;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  async ngOnInit() {
    await this.getQueryParamsRoute();

    await this.getFormDataDetail();
  }

  async getQueryParamsRoute() {
    this.activeRoute$ = await this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.type) this.type = params.type.toUpperCase();
        // if (params.ticketId) this.pricedData.ticket_id = params.ticketId;
        if (params.invoiceNo) this.pricedData['invoice_no'] = params.invoiceNo;

        this.showSubmitButton = this.type == this.refType.USER ? true : false;
      }
    );
  }

  async getFormDataDetail() {
    let fetchUrl =
      this.type == this.refType.CUSTOMER
        ? `get-invoice?invoiceId=${this.pricedData.invoice_no}`
        : `api/appointment/get-customer/${this.pricedData.ticket_id}`;

    this.fetchData$ = await this.apiService
      .get(fetchUrl)
      .subscribe((response) => {
        if (!response.status) return;

        this.pricedData = response['data'];
      });
  }
  getInvoiceDetail(): void {
    // this.pricedData = {
    //   id: 31,
    //   ticket_id: '1060',
    //   rzr_customer_id: 'cust_F6sDsclP1IqpKr',
    //   rzr_invoice_id: 'inv_F6tS35mUMuJPJ5',
    //   rzr_order_id: 'order_F6tS37ktaHGAcM',
    //   expire_at: null,
    //   issue_at: null,
    //   paid_at: null,
    //   amount: null,
    //   cancelled_at: null,
    //   description: 'Test',
    //   short_url: 'https://rzp.io/i/quu2RJn',
    //   payment_status: null,
    //   invoice_receipt: null,
    //   rzr_signature: null,
    //   created_at: '2020-06-25T10:40:20.963Z',
    //   tax_description: 't',
    //   tax_amount: '1.00',
    //   notes: '',
    //   discount: '200.00',
    //   rzr_payment_id: null,
    //   invoice_url: 'https://doc.wait-wise.com/helpdesk/payment?type=CUSTOMER&invoice=inv_F6tS35mUMuJPJ5',
    //   billed_amount: '801.00',
    //   price: '1000.00',
    //   invoice_no: 'inv_F6tS35mUMuJPJ5'
    // };
  }

  makePayment() {
    if (this.pricedData.shortUrl)
      window.open(this.pricedData.shortUrl, '_self');
  }
}
