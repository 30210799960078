import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetManagementService {
  private baseUrl: string;

  constructor(private apiService: ApiService) {
    this.baseUrl =
      environment.api_url === 'https://api-aerodynehelpdesk.wait-wise.com/' ||
      environment.api_url === 'https://api-helpdesk.aerodyne.group/'
        ? 'assets/'
        : '';
  }

  getAssetManagementList(tableName: string) {
    const url = `${tableName}/get-all?limit=1000`;
    return this.apiService.get(this.baseUrl + url);
  }

  getList(tableName: string) {
    const url = `${tableName}?limit=100`;
    return this.apiService.get(this.baseUrl + url);
  }

  postAssetManagementList(tableName: string, payload: any) {
    const url = `${tableName}/edit`;
    return this.apiService.post(this.baseUrl + url, payload);
  }

  deleteAssetManagementListItem(tableName: string, payload: any) {
    const url = `${tableName}/delete`;
    return this.apiService.post(this.baseUrl + url, payload);
  }

  getPartsList(payload: any) {
    const url = `getDataByField?${payload}?limit=100`;
    return this.apiService.get(this.baseUrl + url);
  }

  getAssetById(id: number, tableName: string) {
    const url = `getById?id=${id}&table_name=${tableName}`;
    return this.apiService.get(this.baseUrl + url);
  }

  getDataByField(params: any) {
    const url = 'getDataByField?limit=100';
    return this.apiService.get(this.baseUrl + url, params);
  }

  getSystemConfigData(url: string) {
    return this.apiService.get(this.baseUrl + url);
  }

  getDataByFields(params: any) {
    const url = 'getDataByFieldValues';
    return this.apiService.get(this.baseUrl + url, params);
  }

  getAuditDataByFields(params: any) {
    return this.apiService.get(
      this.baseUrl + 'asset-audit/get-audit-data',
      params
    );
  }
  getDocumentDuplication(params: any) {
    return this.apiService.get(this.baseUrl + 'documents/get-doc-data', params);
  }
  getAssetOwnerDuplication(params: any) {
    return this.apiService.get(
      this.baseUrl + 'asset-owner/get-owner-data',
      params
    );
  }
  postAssetGallery(payload: any) {
    const url = 'asset/img';
    return this.apiService.post(this.baseUrl + url, payload);
  }
  deleteAssetData(params: any) {
    const url = `updateStatus`;
    return this.apiService.post(this.baseUrl + url, params);
  }
}
