import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetManagementMasterApiService {
  public baseUrl: string;

  constructor(private apiService: ApiService) {
    this.baseUrl =
      environment.api_url === 'https://api-aerodynehelpdesk.wait-wise.com/' ||
      environment.api_url === 'https://api-helpdesk.aerodyne.group/'
        ? 'assets/'
        : '';
  }

  getAllMastersList(url: string) {
    return this.apiService.get(this.baseUrl + url);
  }
  postMastersList(url: string, payload: any) {
    return this.apiService.post(this.baseUrl + url, payload);
  }
  amcType() {
    return this.apiService.get(this.baseUrl + 'amc-vendor/amc-type');
  }
  approvalStatus() {
    return this.apiService.get(this.baseUrl + 'asset-history/approval-status');
  }
  assetCondition() {
    return this.apiService.get(this.baseUrl + 'asset-purchase/asset-condition');
  }
  assetDepartments() {
    return this.apiService.get(this.baseUrl + 'asset/asset-departments?limit=100');
  }
  assetStatus() {
    return this.apiService.get(this.baseUrl + 'asset/asset-status');
  }
  assetType() {
    return this.apiService.get(this.baseUrl + 'asset/asset-category?limit=100');
  }
  assignmentStatus() {
    return this.apiService.get(
      this.baseUrl + 'asset-history/assignment-status'
    );
  }
  assignmentType() {
    return this.apiService.get(this.baseUrl + 'asset-history/assignment-type');
  }
  auditType() {
    return this.apiService.get(this.baseUrl + 'asset-audit/audit-type');
  }
  brands() {
    return this.apiService.get(this.baseUrl + 'asset/brands');
  }
  businessType() {
    return this.apiService.get(this.baseUrl + 'asset-vendor/business-type');
  }
  changeType() {
    return this.apiService.get(this.baseUrl + 'asset-history/asset-changetype');
  }
  claimStatus() {
    return this.apiService.get(this.baseUrl + 'asset-warranty/claim-status');
  }
  contractDuration() {
    return this.apiService.get(this.baseUrl + 'amc-vendor/amc-duration');
  }
  depreciationMethod() {
    return this.apiService.get(
      this.baseUrl + 'asset-purchase/depreciation-method'
    );
  }
  disposalMethod() {
    return this.apiService.get(this.baseUrl + 'asset/disposal-method');
  }
  documentType() {
    return this.apiService.get(this.baseUrl + 'documents/document-type');
  }
  incidentCategory() {
    return this.apiService.get(
      this.baseUrl + 'asset-incident/asset-incident-category'
    );
  }
  incidentPriority() {
    return this.apiService.get(
      this.baseUrl + 'asset-incident/asset-incident-priority'
    );
  }
  licenseType() {
    return this.apiService.get(this.baseUrl + 'license/license-type');
  }
  maintenanceStatus() {
    return this.apiService.get(
      this.baseUrl + 'asset-maintenance/asset-servicestatus'
    );
  }
  maintenanceType() {
    return this.apiService.get(
      this.baseUrl + 'asset-maintenance/asset-maintenancetype'
    );
  }
  organizationType() {
    return this.apiService.get(this.baseUrl + 'asset-vendor/organization-type');
  }
  ownerType() {
    return this.apiService.get(this.baseUrl + 'asset-owner/asset-ownertype');
  }
  paymentMethod() {
    return this.apiService.get(this.baseUrl + 'asset-purchase/payment-method');
  }
  paymentStatus() {
    return this.apiService.get(this.baseUrl + 'asset-purchase/payment-status');
  }
  paymentTerms() {
    return this.apiService.get(this.baseUrl + 'asset-vendor/payment-terms');
  }
  returnedCondition() {
    return this.apiService.get(
      this.baseUrl + 'asset-history/asset-returnedcondition'
    );
  }
  vendorType() {
    return this.apiService.get(this.baseUrl + 'asset-vendor/vendor-type');
  }
  vendor() {
    return this.apiService.get(this.baseUrl + 'asset-vendor/get-all?limit=100');
  }
  verificationMethod() {
    return this.apiService.get(
      this.baseUrl + 'asset-audit/audit-verificationmethods'
    );
  }
  warrantyType() {
    return this.apiService.get(this.baseUrl + 'asset-warranty/warranty-type');
  }
}
