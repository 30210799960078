import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  Input,
  ViewChild,
} from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../services/user.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import {
  IDialog,
  EDialogSize,
} from '../../../models/modules/shared/dialog.model';
import { IPanel } from '../../../models/modules/shared/panel.model';
import { ApiService } from '../../../services/api.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { socketService } from 'src/app/services/socket.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() menuBarToggle = new EventEmitter();
  @Output() SearchEvent = new EventEmitter();
  @Output() logout = new EventEmitter();
  // @Input() isViewTicket:any;
  // @Input() ticketDetail={};
  DateService$: Subscription;
  listenCallAlert$: Subscription;
  _menuBarCurrentState: boolean = true;

  ticketName: string = '';
  iframeUrl: any;
  isLogged: Boolean = false;
  isShowIframe: Boolean = false;
  callEnded: Boolean = false;
  alertData: any = {};
  userSession: any;
  incommingCallDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.SMALL,
  };

  headerLogo =
    environment.client == 'kgis'
      ? '../../../../assets/images/logo1.png'
      : '../../../../assets/images/aerodyne_helpdesk_logo.png';
  altName = environment.client == 'kgis' ? 'HELPDESK-CMS' : 'Aerodyne';

  callTransferAlert: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.SMALL,
  };

  calendarModalDialogue: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.SMALL,
  };

  callHangupDialog1: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.SMALL,
  };

  callTransferDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.SMALL,
  };
  viewPanel2: IPanel = {
    showHeader: true,
  };
  ticketSearchCall: any = '';
  incomingPhoneNumber: any = '';
  selectedDisposition: any = {};
  ticketList: any;
  timer: any = '';
  ticketID: any = '';
  callId: any = '';
  callNotes: any = '';
  callModel: Boolean;
  inCall: Boolean = false;
  isNoTicketFound: Boolean = false;
  isInvalidTicket: Boolean = false;
  callInterval: any;
  showSearch: Boolean = true;
  isHold: Boolean = false;
  callDispoModel: Boolean = false;
  vicidialIframe: any;
  isCallDisconnected: Boolean = false;
  isInvalidNumber: Boolean = false;
  transferType: any = '';
  liveAgentList: any = [];
  agentsList: any = [];
  is3WayCall: Boolean = false;
  showTicketCreationBtn: boolean = false;
  callHangUpReason = [
    { name: 'A - Answering Maching', key: 'A', selected: false },
    { name: 'B - Busy', key: 'B', selected: false },
    { name: 'CALLBK - Call back *', key: 'CALLBK', selected: false },
    { name: 'DAIR - Dead Air ', key: 'DAIR', selected: false },
    { name: 'DC - Disconnected Number', key: 'DC', selected: false },
    { name: 'DEC - Declined Sale', key: 'DEC', selected: false },
    { name: 'DNC - DO NOT CALL', key: 'DNC', selected: false },
    { name: 'N - No Answer', key: 'N', selected: false },
    { name: 'NI - Not Interested', key: 'NI', selected: false },
    { name: 'NP - No Pitch No Price', key: 'NP', selected: false },
    { name: 'SALE - Sale made', key: 'SALE', selected: false },
    { name: 'XFER - Call Transferred', key: 'XFER', selected: false },
  ];
  callToNumber: any = '';
  showCreateNewTicketButton = true;
  showSupportGrp = false;
  supportGroupPlaceholder: string = 'Select SupportGroup';

  supportGroups: any[] = [];
  _session: any;
  userId: any;
  selectedSupportGroups: any[];
  supportGrplist: any[] = [];
  showBurgerMenu: boolean = true;
  constructor(
    private dataService: DataService,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private socketService: socketService
  ) {
    if (window.location.href.includes('/dashboard/ticket-details/view/')) {
      requestAnimationFrame(() => {
        this.showBurgerMenu = false;
      });
    }
  }

  ngOnInit(): void {
    this.getMasterDisposition();
    this.dataService.currentRoute.subscribe((route) => {
      this.getCurrentRoute(route);
    });
    this._session = this.userService.getSession();

    const data = this._session['support_group'];
    let responseData = data.map((group) => {
      return { label: group.name, value: group.id };
    });
    this.supportGroups = responseData.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    // this.getAllSupportGroups();

    this.userId = this._session['id'];

    this.listenCallAlert$ = this.socketService
      .getMessage(`_LISTEN_CALL`)
      .subscribe((msg) => {
        this.alertData = msg.value;
        this.callTransferAlert.display = true;
      });
    this.DateService$ = this.dataService.outGoing.subscribe((text) => {
      // this.ticketText = text
      if (text == 'OUTGOING_DONE') {
        this.getIframeUrl(new Date().valueOf());
      } else {
      }
      // this.getTicketList(text)
    });
    // this.getTotalCount()
    this.getIframeUrl(new Date().valueOf().toString());

    var eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
  }
  getCurrentRoute(route) {
    this.showSupportGrp = route == '/dashboard' ? true : false;
    this.showTicketCreationBtn =
      route === '/dashboard' ||
      route.startsWith('/dashboard/ticket-details/view/')
        ? true
        : false;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showCreateNewTicketButton =
          event.url != '/dashboard' ? false : true;
      }
    });
  }

  getMasterDisposition() {
    this.apiService.get('list-disposition').subscribe((response) => {
      this.callHangUpReason = response.data;
    });
  }

  searchTicketForCall = () => {
    let data = {
      ticketId: this.ticketSearchCall.substr(this.ticketSearchCall.length - 10),
    };
    this.apiService.post('search-ticket', data).subscribe((response) => {
      if (response.data.length != 0) {
        this.ticketList = response.data;

        this.showSearch = false;
        this.isNoTicketFound = false;
      } else {
        this.isNoTicketFound = true;
      }

      // this.ticketList = (value) ? this.ticketList : []
    });
  };

  onClickMenuBar() {
    this._menuBarCurrentState = !this._menuBarCurrentState;
    this.menuBarToggle.emit(this._menuBarCurrentState);
  }

  SearchTicket() {
    this.dataService.ticketSearch(this.ticketName);
  }
  _logout() {
    this.logout.emit(true);
  }

  getIframeUrl = (currentTime: any = '') => {
    let _url = '';
    const _session = this.userService.getSession();
    this.userSession = _session;

    this.isShowIframe = true;
    if (!_session['vicidialAgentId']) {
      return (this.isShowIframe = false);
    }

    if (_session) {
      this.isLogged = true;
    }
    _url = `${environment.vicidial_url}?purpose=IN&showOnly=status&userId=${_session['id']}&login=YES&=8600056&VD_login=${_session['vicidialAgentId']}&VD_campaign=${_session['vicidialAgentCampaign']}&phone_login=${_session['vicidialAgentId']}&phone_pass=${_session['vicidialAgentPassword']}&VD_pass=${_session['vicidialAgentPassword']}&api_url=${environment.api_url}&currentTime=${currentTime}`;

    // _url =`https://103.21.34.19/agc/helpdesk/vicidial.php?purpose=IN&showOnly=status&userId=7c04e058-7685-48c6-9eae-35bbd2604fbb&login=YES&=8600056&VD_login=8090&VD_campaign=MIS&phone_login=8090&phone_pass=M1s090tM&VD_pass=M1s090tM&api_url=https://helpdesk-api.wait-wise.com/&currentTime=`
    if (environment.production) {
      this.apiService
        .validateVicidalUrl(`${environment.vicidial_api_url}?function=version`)
        .subscribe(
          (res) => {},
          (error) => {
            let iframe: any = document.getElementById('vicidialIframeHeader');
            iframe.remove();
          }
        );
    }
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(_url);

    setTimeout(() => {
      let iframe: any = document.getElementById('vicidialIframeHeader');
      if (iframe) {
        iframe.contentWindow.postMessage({ type: 'pause' }, '*');
      }
    }, 3000);
  };

  secToTimer(sec) {
    let o = new Date(0);
    let p = new Date(sec * 1000);
    return new Date(p.getTime() - o.getTime())
      .toISOString()
      .split('T')[1]
      .split('Z')[0]
      .split('.000')[0];
  }

  leave3wayCallResponse = () => {
    let iframe: any = document.getElementById('vicidialIframeHeader');
    iframe.contentWindow.postMessage({ type: 'resume' }, '*');
    this.selectedDisposition = {};
    this.callHangupDialog1.display = true;
    this.callEnded = true;
    this.callHangUpReason.map((item) => {
      item.selected = false;
    });
    this.incommingCallDialog.display = true;
    this.callModel = false;
    this.inCall = false;

    let newTicketId = sessionStorage.getItem('ticketId');
    if (newTicketId) {
      this.ticketID = newTicketId;
    }
    iframe.contentWindow.postMessage({ type: 'pause' }, '*');
    if (this.callInterval) {
      clearInterval(this.callInterval);
    }
  };

  sendEventToIframe = (eventName) => {
    let iframe: any = document.getElementById('vicidialIframeHeader');
    iframe.contentWindow.postMessage({ type: 'resume' }, '*');
    iframe.contentWindow.postMessage({ type: eventName }, '*');
    if (eventName == 'call') {
      this.callEnded = false;
      // document.getElementById("overlay-call").style.display = "block";
    } else {
      this.selectedDisposition = {};
      this.callHangupDialog1.display = true;
      this.callEnded = true;
      this.callHangUpReason.map((item) => {
        item.selected = false;
      });
      this.incommingCallDialog.display = false;
      this.callModel = false;
      this.inCall = false;

      let newTicketId = sessionStorage.getItem('ticketId');
      if (newTicketId) {
        this.ticketID = newTicketId;
      }
      iframe.contentWindow.postMessage({ type: 'pause' }, '*');
      if (this.callInterval) {
        clearInterval(this.callInterval);
      }
    }
  };

  changeCallStatus = (status) => {
    this.apiService
      .vicidial_post(
        `?source=test&user=${this.userSession['vicidialAgentId']}&pass=${this.userSession['vicidialAgentPassword']}&agent_user=${this.userSession['vicidialAgentId']}&function=park_call&value=${status}`,
        {}
      )
      .subscribe((result) => {
        if (status == 'PARK_CUSTOMER') {
          this.isHold = true;
        } else {
          this.isHold = false;
        }
      });
  };

  selectDisposition = (data) => {
    this.callHangUpReason.map((item) => {
      item.selected = false;
    });
    data.selected = true;
    this.selectedDisposition = data;
  };
  updateDisposition = () => {
    this.apiService
      .vicidial_post(
        `?source=test&user=${this.userSession['vicidialAgentId']}&pass=${this.userSession['vicidialAgentPassword']}&agent_user=${this.userSession['vicidialAgentId']}&function=external_status&value=${this.selectedDisposition.key}`,
        {}
      )
      .subscribe((result) => {
        clearInterval(this.callInterval);
        this.updateCallTimeLine();
      });
  };

  updateCallTimeLine = () => {
    let request = {
      ticketId: null,
      ticketIDText: this.ticketID,
      callId: this.callId,
      type: 'INBOUND',
      disposition: this.selectedDisposition.name,
      notes: this.callNotes,
      transferType: this.transferType,
      transferedTo: this.callToNumber,
    };

    this.apiService.post('save-call-time-line', request).subscribe(
      (res) => {
        if (res.status) {
          this.updateCallStatus('READY');
          this.callEnded = false;
          this.callHangupDialog1.display = false;
          this.incommingCallDialog.display = false;
          sessionStorage.removeItem('ticketId');
          sessionStorage.removeItem('incoming');
          window.location.reload();
          this.vicidialIframe.contentWindow.postMessage(
            { type: 'resume' },
            '*'
          );
        } else {
          if (res.invalidTicket) {
            this.isInvalidTicket = true;
          } else {
            this.isInvalidTicket = false;
          }
        }

        // this.logoutVicidial();
        // this.getTicketDetails(this.ticketDetail.id)
      },
      (error) => {}
    );
  };

  controlModal = (status) => {
    this.callModel = status;
    this.incommingCallDialog.display = status;
  };

  controlModalDispo = (status) => {
    let newTicketId = sessionStorage.getItem('ticketId');
    if (newTicketId) {
      this.ticketID = newTicketId;
    }
    this.callDispoModel = status;
    this.callHangupDialog1.display = status;
  };

  modalClosedCall = () => {
    this.sendEventToIframe('hangup');
  };

  viewTicket = (ticketId, tId) => {
    this.dataService.viewTicketDetail(ticketId);
    this.callModel = false;
    this.ticketID = tId;
    this.incommingCallDialog.display = false;
  };
  createTicket() {
    this.dataService.openCreateTicketModal({ open: true });
  }

  openCalendar() {
    this.calendarModalDialogue.display = true;
  }

  transferCall = () => {
    let _url = '';
    if (this.callToNumber.toString().length > 3) {
      if (this.transferType == 'BLIND') {
        _url = `source=test&user=${this.userSession['vicidialAgentId']}&pass=${this.userSession['vicidialAgentPassword']}&agent_user=${this.userSession['vicidialAgentId']}&function=transfer_conference&value=BLIND_TRANSFER&phone_number=${this.callToNumber}`;
      } else {
        _url = `source=test&user=${this.userSession['vicidialAgentId']}&pass=${this.userSession['vicidialAgentPassword']}&agent_user=${this.userSession['vicidialAgentId']}&function=transfer_conference&value=DIAL_WITH_CUSTOMER&phone_number=${this.callToNumber}&dial_override=Y&consultative=YES&ingroup_choices=AGENTDIRECT`;
        // _url = `source=test&user=${this.userSession['vicidialAgentId']}&pass=${this.userSession['vicidialAgentPassword']}&agent_user=${this.userSession['vicidialAgentId']}&function=transfer_conference&value=DIAL_WITH_CUSTOMER&phone_number=${this.callToNumber}&dial_override=YES&consultative=YES`;
      }
    } else {
      this.isInvalidNumber = true;
      return 1;
    }

    this.isInvalidNumber = false;
    this.apiService.vicidial_post(`?${_url}`, {}).subscribe((result) => {
      if (this.transferType == 'BLIND') {
        this.callHangupDialog1.display = true;
      } else {
        this.is3WayCall = true;
      }

      this.callTransferDialog.display = false;
      let reqData = {
        user_id: this.selectedAgent,
        from: this.userSession['vicidialAgentId'],
        to: this.callToNumber,
        type: this.transferType,
      };
      this.apiService.post('transfer-call-alert', reqData).subscribe(
        (res) => {},
        (error) => {}
      );
    });
  };

  // parkCall
  parkCall = () => {
    this.apiForCustomerParking('PARK_CUSTOMER');
  };

  unparkCall = () => {
    this.apiForCustomerParking('GRAB_CUSTOMER');
  };

  leaveCall = async () => {
    const _url = `source=test&user=${this.userSession['vicidialAgentId']}&pass=${this.userSession['vicidialAgentPassword']}&agent_user=${this.userSession['vicidialAgentId']}&function=transfer_conference&value=LEAVE_3WAY_CALL`;
    await this.vicidialCall(_url);
    // if (response) {
    this.leave3wayCallResponse();
    // }
  };

  // grabParkedCall

  // API for customer holding // warm transfer
  apiForCustomerParking = async (status = '') => {
    const _url = `source=test&user=${this.userSession['vicidialAgentId']}&pass=${this.userSession['vicidialAgentPassword']}&agent_user=${this.userSession['vicidialAgentId']}&function=park_call&value=PARK_CUSTOMER`;
    await this.vicidialCall(_url);
  };

  vicidialCall = async (url) => {
    this.apiService.vicidial_post(`?${url}`, {}).subscribe((result) => {
      return result;
    });
  };

  openCallTransferDialog = () => {
    this.callTransferDialog.display = true;
    this.getAgentListVicidial();
  };

  getAgentListVicidial = () => {
    this.apiService.get(`get-active-user`).subscribe((result) => {
      this.agentsList = result['data'];
      this.agentsList.map((item) => {
        item.agent_value = `${item.fullname} - ${item.vicidialAgentId}`;
      });
      this.agentsList.push({ agent_value: 'External Number', id: 'External' });
    });
  };
  selectedAgent: string = '';
  onAgentSelectChange = (event) => {
    this.selectedAgent = event.value.id;
    if (event.value.id != 'External') {
      // this.selectedAgent = event.value.id
      this.callToNumber = event.value.vicidialAgentId;
    } else {
      this.callToNumber = '';
      this.selectedAgent = event.value.id;
    }
  };
  updateCallStatus = (status) => {
    let data = { user: this.userSession['id'], status: status };
    this.apiService
      .post(`update-user-call-status`, data)
      .subscribe((result) => {});
  };
  closeAlertDialog = () => {
    this.callTransferAlert.display = false;
  };

  getAllSupportGroups(): void {
    this.apiService.get('get-support-group?sort=ASC&orderBy=name').subscribe(
      (result) => {
        if (result.data) {
          const group = result.data.map((group) => {
            return { label: group.name, value: group.id };
          });
          this._session &&
          (this._session['role'].role_name == 'Admin' ||
            this._session['role'].role_name == 'Super admin')
            ? (this.supportGroups = group)
            : (this.supportGroups = this.supportGroups);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  searchTicketByAssignGrp() {
    this.dataService.updateSupportSearch(true);
    this.dataService.updateAssignmentGroupFilterStatus(
      this.selectedSupportGroups
    );
  }

  onSupportGroupChange(event: any) {
    this.supportGrplist = event?.value;
    this.dataService.updateSelectedSupportGroups(this.supportGrplist);
  }

  resetFilter() {
    this.router.navigate(['/dashboard']).then(() => {
      this.titleService.setTitle(
        'Aerodyne Group - Drone Tech, Data Tech & Digital Transformation'
      );
      this.showBurgerMenu = true;
      this.menuBarToggle.emit(true);
      // this.router.events.subscribe((event) => {
      //   if (event instanceof NavigationEnd) {
      window.location.reload();
      //   }
      // });
    });
  }
}
