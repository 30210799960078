<!-- Add and Edit Header -->
<p-menubar class="table_head" stylceClass="customMenu"
    *ngIf="addButton || editButton || viewButton || dataTable?.menuHeading || enableRow">
    <span class="grid_header">{{dataTable?.menuHeading}}</span>
    <div class="table_head_right">
        <!-- <ng-content select="#search"></ng-content> -->
        <div class="bulk-upload" *ngIf="bulkUpload">
            <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="Bulk Upload" class="p-btn-sm"
                (click)="openCreateTicket()" style="padding: 0.2em;"></button>
        </div>
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search"
            *ngIf="searchOptionOnly" />
        <button pButton label="Add" class="add_button btnBgWidthBorder" icon="pi pi-plus" *ngIf="addButton"
            (click)="openDialogue('add','')"></button>
    </div>
</p-menubar>

<!-- Data Table Starts -->
<p-table #dt styleClass="ui-table" [columns]="columns" [value]="data" [lazy]="isLazyLoading" (onLazyLoad)="loadCarsLazy($event)"
    [paginator]="true" [rows]="dataTable?.rows" [totalRecords]="totalRecords" [filterDelay]="0" [loading]="loading"
    [showCurrentPageReport]="true" [rowsPerPageOptions]="pageSizeOptions" paginatorDropdownAppendTo="body"
    [globalFilterFields]="fields" currentPageReportTemplate="Showing {first} to {last} of {{dataTable?.totalRecords}} entries"
    [exportFilename]="dataTable?.menuHeading" (onSort)="onSort($event)">

    <ng-template pTemplate="caption" *ngIf="csvExcelPdfButton">

        <div class="ui-helper-clearfix" style="text-align: left">

            <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dt.exportCSV()"
                class="p-btn-sm" style="margin-right: 0.5em;"></button>
            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel()"
                style="margin-right: 0.5em;" class="ui-button-success p-btn-sm"></button>
            <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf()"
                class="ui-button-warning p-btn-sm"></button>
            <div class="ui-table-globalfilter-container">
                <input *ngIf="!isLazyLoading" pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search" />
                <input *ngIf="isLazyLoading" pInputText type="text" (input)="ticketRecordSearch($event.target.value)"
                    placeholder="Search" />
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>

        <tr>
            <th id="table-head" *ngFor="let col of columns" [ngClass]="col.width" pSortableColumn="{{col.field}}" 
                [ngClass]="{'custom-width': isUsersPage && (col.header === 'Email'),
              'custom-action-width': (isTicketList && (col.header === 'Priority' || col.header === 'Urgency')) || 
              (isUsersPage && (col.header === 'Status' || col.header === 'Role' || col.header === 'ID' || col.header === 'Mobile')) ||
              (isAssignmentGroup && (col.header === 'Status'))
              }" [class.ticket-records]="dataTable?.exportFileName && (dataTable?.exportFileName == 'Ticket Records' || dataTable?.exportFileName == 'Health Snapshot')">
                {{col.header}} <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </th>

            <th id="table-head-action" *ngIf="viewButton || editButton || approveButton || openButton || deleteButton"
                class="MEDIUM_1" [ngClass]="{'custom-action-width': isUsersPage || isAssignmentGroup || isTicketList}" 
                [class.ticket-records]="dataTable?.exportFileName && (dataTable?.exportFileName == 'Ticket Records' || dataTable?.exportFileName == 'Health Snapshot')">
                Action
            </th>
        </tr>

    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns;let i = index"
                [class.centerAlign]="col.field =='status' || col.field =='vendor_status'"
                (click)="handleRow(rowData, col)"
                [ngClass]="{'single-line-style': (isAssignmentGroup && (col.header === 'Users') || (col.header === 'Ticket Response Description')), 'cursorCls': rowData[col.field] && enableRow}">
                <span class="tooltip-item" pTooltip="{{rowData[col.field]}}" tooltipPosition="bottom"
                    *ngIf="(col.field == 'ticketResponseDescription') || (isAssignmentGroup && (col.field == 'usersName'))  ">
                    {{(rowData[col.field])? (rowData[col.field]):'-'}}
                </span>
                <span *ngIf="col.field =='status' && isAssignmentGroup">
                    {{rowData[col.field]}}
                </span>
                <span class="inputData"
                    *ngIf="!col.type && col.field!='createdAt' && col.field !='status'&& col.field !='vendor_status' && col.field != 'usersName' && col.field !== 'ticketResponseDescription'">
                    {{(rowData[col.field])? (rowData[col.field]):'-'}}
                </span>
                <span class="inputData"
                    *ngIf="col.type == 'link'">
                    <a [href]="linkBaseUrl + rowData?.url" target="_blank">{{(rowData[col.field])? (rowData[col.field]):'-'}}</a>
                </span>
                <span *ngIf="!col.type && col.field =='createdAt'">
                    {{rowData[col.field] | date :'EEE, dd MMM yyyy h:mm a'}}
                </span>
                <span *ngIf="col.type && col.type =='date'">
                    {{rowData[col.field] | date :'EEE, dd MMM yyyy h:mm a'}}
                </span>
                <span *ngIf="col.type && col.type =='limitText'" class="limited-text" [title]="rowData[col.field] ">
                    {{rowData[col.field] }}
                </span>
                <label *ngIf="col.field =='status' && !isAssignmentGroup" class="toggleSwitch">
                    <input type="checkbox" #toggle type="checkbox" [checked]="rowData.status  == 'Active'? true : false"
                        (change)="handleChange(toggle,rowData)" [disabled]="!dataTable.disableStatus">
                    <span class="slider round"></span>
                </label>
                <label *ngIf="col.field =='vendor_status'" class="toggleSwitch">
                    <input type="checkbox" #toggle type="checkbox"
                        [checked]="rowData.vendor_status == 'Active'? true:false"
                        (change)="handleChange(toggle,rowData)">
                    <span class="slider round"></span>
                </label>
                <!-- <input #toggle  *ngIf="col.field =='status'" type="checkbox" [checked]="rowData.status == 'Active'? true:false"  (change)="handleChange(toggle,rowData)"> -->

                <!-- <span *ngIf="col.field == 'status'" >
                    {{(rowData[col.field] === true || rowData[col.field] === "ACTIVE" || rowData[col.field] === "1") ?'Active': 'Inactive'}}</span> -->

            </td>

            <td *ngIf="viewButton || editButton || approveButton || openButton || deleteButton || kbseleteBtn"
                class="text-center action-btns">
                <span *ngIf="editButton" (click)="openDialogue('edit', rowData)"> <i class="fas fa-edit pointer"
                        title="Edit"></i></span>

                <span *ngIf="viewButton" (click)="selectedData('view',rowData)"> <i *ngIf="viewButton"
                        class="fas fa-eye pointer" title="View"></i> </span>

                <span *ngIf="approveButton" class="pointer" (click)="selectedData('approve',rowData)"><i
                        class="fas fa-check pointer success-color" title="Approve"></i> </span>

                <span *ngIf="openButton" class="pointer" (click)="selectedData('open',rowData)"><i
                        class="fas fa-external-link-alt" title="Open"></i> Edit</span>

                <span *ngIf="kbsdeleteBtn" (click)="customDelete(rowData)"> <i class="fas fa-trash pointer"
                        title="Delete"></i></span>

                <span *ngIf="deleteButton" (click)="deleteEvent(rowData)"> <i class="fas fa-trash pointer delete-btn"
                        title="Delete"></i></span>
                <span *ngIf="downloadButton" (click)="downloadEvent(rowData.fileLocation)"> <i
                        class="fas fa-download pointer" title="Download"></i></span>

            </td>
            <!-- <td *ngIf="viewButton" class="text-center" (click)="selectedData(rowData)">
                <i class="fas fa-eye pointer"></i>
            </td>
            <td *ngIf="approveButton" class="text-center" (click)="selectedData(rowData)">
                <i class="fas fa-check pointer success-c\olor"></i>
            </td>
            <td *ngIf="openButton" class="text-center" (click)="selectedData(rowData)">
                <i class="fas fa-times pointer danger-color"></i>
            </td>
             -->
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" style="text-align:left">No Data found.</td>
            <div class="" style="position: absolute; z-index: 1; top: 50%; left: 50%;" *ngIf="spinner">
                <img class="spinnerLogo" [src]="aeroLogo" alt="aero logo">
            </div>
        </tr>
    </ng-template>
</p-table>

<!-- Default Dialogue Starts -->
<common-dialog [dialogConfig]="defaultDialog">
    <ng-content></ng-content>
</common-dialog>

<confirmation-dialog #confirmDialog (confirm)="confirmDelete($event)"></confirmation-dialog>

<common-dialog [dialogConfig]="bulkUploadButton">
    <div class="p-grid">
        <div class="p-col-12">
            <panel [panelConfig]="viewPanel1" class="fixed-panel">
                <div panelHeader>
                    <span>Bulk Upload</span>
                </div>
                <div panelBody class="panel-body">
                    <div class="template p-p-2">
                        <h3 class="p-mb-2">Download Template</h3>
                        <button type="button" pButton icon="pi pi-download" iconPos="left" label="Template"
                            class="p-btn-sm" style="padding: 0.2em;" (click)="downloadFile()"></button>
                    </div>
                    <div class="upload-files">
                        <div class="p-field p-col-12">
                            <h3 class="p-d-block p-mb-2">Import From</h3>
                            <div class="upload-btn-wrapper" style="width: 100%;">
                                <button class="file-upload" style="width: 100%;">Click here to choose a
                                    file</button>
                                <input type="file" #timeLineFile (change)="onImportFileChange($event)" />
                            </div>
                        </div>
                    </div>
                    <p class="p-pl-2" *ngIf="validImportedData.length !==0"><strong>No. of records -</strong>
                        {{validImportedData.length}} <span class="p-pl-2" *ngIf="bulkUploadResponse?.error"> -
                            {{bulkUploadResponse?.error?.message}}</span>
                    </p>


                    <div class="p-pl-2" *ngIf="bulkUploadResponse?.status == true">
                        <p><strong>No. of records uploaded -</strong> {{bulkUploadResponse?.data?.added?.length}}</p>
                        <p><strong>No. of records skipped -</strong> {{bulkUploadResponse?.data?.skipped?.length}}</p>
                        <p *ngFor="let skippedData of bulkUploadResponse?.skipped">
                            <span>{{skippedData}}</span>
                        </p>


                    </div>
                    <div class="p-d-flex p-jc-end p-mr-2">
                        <button pButton class="p-btn-sm" icon="pi pi-upload" style="padding: 0.2em;" iconPos="left"
                            label="Import" (click)="bulkUploadRequestor()"></button>
                    </div>
                </div>
            </panel>
        </div>
    </div>
</common-dialog>
<message-modal #messageModal></message-modal>