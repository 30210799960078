<div class="p-grid user-container" id="licenseFormContainer">
  <div class="p-col-12 p-jc-arround">
    <form class="ui-fluid p-grid" [formGroup]="documentUploadForm">
      <div class="p-col-12 p-md-6 ">
        <label class="userLabel required-field" for="documentType">Document Name</label>
        <input class="ui-inplace p-p-2 w-100" placeholder="Enter document name" type="text" name="fileName"
          style="width: 100%;" formControlName="documentName">
        <p-message severity="error" text="Document Name is required" *ngIf="
                  !documentUploadForm?.controls['documentName'].valid &&
                  documentUploadForm?.controls['documentName'].touched">
        </p-message>
      </div>
      <div class="p-col-12 p-md-6">
        <label class="userLabel required-field" for="documentType">Document Type</label>
        <p-dropdown class="" id="documentType" placeholder="Select" formControlName="documentType"
          [options]="documentTypeList" optionLabel="label"></p-dropdown>
        <p-message severity="error" text="Document Type is required" *ngIf="
                  !documentUploadForm?.controls['documentType'].valid &&
                  documentUploadForm?.controls['documentType'].touched">
        </p-message>
      </div>
    </form>
    <div class="p-field p-col-12">
      <label class="userLabel required-field p-d-block p-mb-2">Choose Your file</label>
      <div class="upload-btn-wrapper" style="width: 100%;">
        <button class="file-upload" style="width: 100%;">Choose a file</button>
        <span *ngIf="selectedFile">{{selectedFile +' added'}} </span>
        <input type="file" #timeLineFile (click)="timeLineFile.value = ''" [moduleName]="moduleName" documentUpload
          (getDocument)="onGetDocumentData($event)" />
      </div>
      <div class="submit-button">
        <button pButton class="btnBg upload-btn" label="Upload" [disabled]="!(documentUploadForm.valid && selectedFile)"
          (click)="uploadDocumentData()">
        </button>
      </div>
    </div>
    <div class="p-col-12">
      <data-table [closeDialog]="closeDialog" [dataTable]="dataTable"
        (OutputData)="passDataToForm($event)"></data-table>
    </div>
  </div>
</div>

<message-modal #messageModal></message-modal>