import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import * as XLSX from 'xlsx';
@Injectable({ providedIn: 'root' })
export class DataService {
  private messageSource = new BehaviorSubject('');
  TicketText = this.messageSource.asObservable();

  private outGoingSource = new BehaviorSubject('');
  outGoing = this.outGoingSource.asObservable();

  private viewTicketSource = new BehaviorSubject('');
  viewTicketDetails = this.viewTicketSource.asObservable();

  private openTktSource = new BehaviorSubject('');
  OpenTicketModal = this.openTktSource.asObservable();

  private selectedSupportGroupsSource = new BehaviorSubject<any[]>([]);
  selectedSupportGroups$ = this.selectedSupportGroupsSource.asObservable();

  private supportGroups = new BehaviorSubject<boolean>(false);
  supportGroups$ = this.supportGroups.asObservable();

  private ticketType = new BehaviorSubject(0);
  selectedType$ = this.ticketType.asObservable();

  private requestTypesSubject = new BehaviorSubject<any[]>([]);
  requestTypes$ = this.requestTypesSubject.asObservable();

  private route = new BehaviorSubject('');
  currentRoute = this.route.asObservable();

  private assignmentGroupFilter = new BehaviorSubject<any[]>([]);
  assignmentGroupFilterStatus = this.assignmentGroupFilter.asObservable();

  ticketSearch(message: string) {
    this.messageSource.next(message);
  }

  outgoingDone(message: string) {
    this.outGoingSource.next(message);
  }
  viewTicketDetail(message: string) {
    this.viewTicketSource.next(message);
  }
  openCreateTicketModal(value) {
    this.openTktSource.next(value);
  }
  updateSelectedSupportGroups(groups: any[]) {
    this.selectedSupportGroupsSource.next(groups);
  }
  updateSupportSearch(value) {
    this.supportGroups.next(value);
  }
  updateTicketType(id: number) {
    this.ticketType.next(id);
  }
  updateRoute(route: string) {
    this.route.next(route);
  }

  updateRequestType(data: any[]) {
    this.requestTypesSubject.next(data);
  }

  updateAssignmentGroupFilterStatus(status: any[]) {
    this.assignmentGroupFilter.next(status);
  }

  // .=-=-=-=-=-=-=-=- excel import .=-=-=-=-=-=-=-=-
  excelToJson(file: File, mandatoryFields: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // FileReader onload event
      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

        // Check for mandatory fields and validate rows
        const validRows = [];
        const invalidRows = [];
        jsonData.forEach((row: any, index: number) => {
          let isValidRow = true;
          mandatoryFields.forEach((field) => {
            if (!row[field]) {
              isValidRow = false;
              invalidRows.push({ row: index + 1, field });
            }
          });
          if (isValidRow) {
            // Convert keys from camelCase to snake_case
            const convertedRow = this.convertKeysToSnakeCase(row);
            validRows.push(convertedRow);
          }
        });

        if (validRows.length > 0) {
          resolve({ data: validRows, invalidRows });
        } else {
          reject({ message: 'No valid data found', invalidRows });
        }
      };

      // FileReader onerror event
      reader.onerror = (error) => {
        reject(error);
      };

      // Start reading the file as binary string
      reader.readAsBinaryString(file);
    });
  }

  // convert object keys from camelCase to snake_case
  private convertKeysToSnakeCase(obj: any): any {
    const snakeCaseObj: any = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const trimmedKey = key.trim(); // Trim leading and trailing spaces
        const snakeCaseKey = trimmedKey.replace(/\s+/g, '_').toLowerCase(); // Convert to snake_case
        snakeCaseObj[snakeCaseKey] = obj[key];
      }
    }
    return snakeCaseObj;
  }
}
