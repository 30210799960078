 
<p-card>
    <p class="p-pt-1 p-pb-3"><b>Ticket Records</b></p>
    <panel [panelConfig]="viewPanel2">
        <div panelHeader class="sub-heading">
            <span>Filter by
                <span *ngIf="showFilterBy">
                    : Total Records: {{ticketCount ? ticketCount : 0}}
                    <!-- {{selectedDayFilter ? ('-' + selectedDayFilter?.name) + ',' : ''}}
                    {{ formatRangeDates() }}
                    {{selectedPriorityFilter?.length ? (selectedPriorityFilter?.name) + ',' : ''}}
                    {{selectedServiceFilter?.length ? (selectedServiceFilter?.name) + ',' : ''}}
                    {{selectedStatusFilter?.length ? (selectedStatusFilter?.status) + '' : ''}} -->
                </span>
            </span>
        </div>
        <div panelBody>
            <div class="cardgrd p-p-3">
                <div class="p-grid card-grid p-mt-2">
                    <div class="p-col-2 hideUser">
                        <label class="form-label">Company</label> <br>
                        <p-multiSelect width="100" [options]="companiesList" [(ngModel)]="selectedCompanyFilter"
                            optionLabel="name" placeholder="choose a Company" styleClass="selector"
                            appendTo="body"></p-multiSelect>
                    </div>
                    <div class="p-col-2 hideUser">
                        <div>
                            <label class="form-label">Time Range</label> <br>
                            <p-dropdown width="100" [options]="chooseTimelineFilter" [(ngModel)]="selectedDayFilter"
                                (ngModelChange)="handleDayFilterChange()" optionLabel="name"
                                placeholder="select day, week, month, year" styleClass="selector-1"
                                appendTo="body"></p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-2 hideUser" *ngIf="showCalendar">
                        <label class="form-label">Date Range</label>
                        <p-calendar width="100" class="ui-fluid" [(ngModel)]="rangeDates" (onSelect)="rangeSelected()"
                            appendTo="body" selectionMode="range" [readonlyInput]="true"
                            placeholder="select date range"></p-calendar>
                    </div>
                    <!-- <div class="p-col-2 hideUser">
                        <label class="form-label">Ticket Assigned</label>
                        <p-dropdown [options]="ticketType" [(ngModel)]="selectedTicketType" optionLabel="name"
                            (ngModelChange)="handleTicketFilterChange()" placeholder="" styleClass="selector-1"
                            appendTo="body"></p-dropdown>
                    </div> -->
                    <div class="p-col-2 hideUser">
                        <label class="form-label">Priority</label> <br>
                        <p-multiSelect width="100" [options]="Priority" [(ngModel)]="selectedPriorityFilter"
                            optionLabel="name" placeholder="choose a Priority" styleClass="selector"
                            appendTo="body"></p-multiSelect>
                    </div>
                    <div class="p-col-2 hideUser">
                        <label class="form-label">Assignment Group</label> <br>
                        <p-multiSelect width="100" [options]="serviceFilterList" [(ngModel)]="selectedServiceFilter"
                            optionLabel="name" placeholder="choose a assignment group" styleClass="selector"
                            appendTo="body"></p-multiSelect>
                    </div>
                    <div class="p-col-2 hideUser">
                        <label class="form-label">Status</label> <br>
                        <p-multiSelect width="100" #agentDropdown appendTo="body" styleClass="selector"
                            [options]="statusFilterList" optionLabel="status" [filter]="true" filterBy="label"
                            showClear="true" placeholder="Select Status" [(ngModel)]="selectedStatusFilter">
                            <!-- <ng-template pTemplate="selectedStatusFilter">
                                <div class="agent-item agent-item-value" *ngIf="selectedStatusFilter">
                                    <div>{{selectedStatusFilter.status}}</div>
                                </div>
                            </ng-template>
                            <ng-template let-i pTemplate="item">
                                <div class="country-item">
                                    <div> {{i.label}}</div>
                                </div>
                            </ng-template> -->
                        </p-multiSelect>
                    </div>
                    
                    <div class="p-col-2 hideUser">
                        <label class="form-label">Request Type</label> <br>
                        <p-multiSelect width="100" [options]="requestTypes" [(ngModel)]="selectedRequestTypeFilter"
                            optionLabel="name" placeholder="choose a Request Type" styleClass="selector"
                            appendTo="body"></p-multiSelect>
                    </div>
                    <!-- <div class="p-col-2 hideUser" *ngIf="showUser">
                        <label class="form-label">User</label>
                        <p-dropdown #agentDropdown appendTo="body" styleClass="selector-1" [options]="userList" optionLabel="name"
                            [filter]="true" filterBy="label" showClear="true" placeholder="Select an user"
                            [(ngModel)]="selectedAgentFilter">
                            <ng-template pTemplate="selectedAgentFilter">
                                <div class="agent-item agent-item-value" *ngIf="selectedAgentFilter">
                                    <div>{{selectedAgentFilter.name}}</div>
                                </div>
                            </ng-template>
                            <ng-template let-i pTemplate="item">
                                <div class="country-item">
                                    <div>{{i.label}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div> -->
                    <div id="filterButtons" class="p-d-flex p-ai-end p-jc-end p-col-12">
                        <!-- <div class="p-mb-1">
                            <p-checkbox name="overDueStatus" binary="true" label="Check Overdue Tickets"
                                [(ngModel)]="overDueStatus"></p-checkbox>
                        </div> &nbsp;&nbsp;&nbsp;&nbsp; -->
                        <button pButton width="100" type="button" label="Apply Filter" (click)="getFilterTicket()"
                            class="p-py-1 ui-button btn-submit btnBg"></button> &nbsp;&nbsp;&nbsp;&nbsp;
                        <button pButton width="100" type="button" label="Clear Filter"
                            class="p-py-1 ui-button ui-button-danger" (click)="clearFilter()"></button>
                    </div>
                </div>
            </div>
        </div>
    </panel>
    <div class="table p-mt-2">
        <data-table [closeDialog]="closeDialog" [dataTable]="dataTable" (OutputData)="passDataToForm($event)" (tableRefresh)="getFilterTicket()" 
        (paginationChange)="onPaginationChange($event)" (ticketSearch)="customizedTableSearch($event)">
        </data-table>
    </div>
    <div class="" style="position: absolute; z-index: 1; top: 50%; left: 50%;" *ngIf="spinner">
        <img class="spinnerLogo" [src]="aeroLogo" alt="aero logo">
    </div>
</p-card>