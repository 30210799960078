import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { IDataTable } from '../../../models/modules/shared/data-table.model';
import {
  IDialog,
  EDialogSize,
} from '../../../models/modules/shared/dialog.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IPanel } from 'src/app/models/modules/shared/panel.model';
import { DataService } from 'src/app/services/data.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
interface formType {
  type: string;
  value?: Object;
}

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() dataTable: IDataTable;
  @Input() closeDialog: any;
  @Input() isUsersPage!: boolean;
  @Input() isAssignmentGroup!: boolean;
  @Input() isTicketList!: boolean;
  @Output() OutputData: EventEmitter<Object> = new EventEmitter<Object>();
  @ViewChild('confirmDialog') confirmDialog: ConfirmationDialogComponent;
  @Output() rowEmitter: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() tableRefresh: EventEmitter<string> = new EventEmitter<string>();
  @Output() paginationChange: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() ticketSearch: EventEmitter<string> = new EventEmitter<string>();
  spinner: boolean = false;
  aeroLogo = '../../../../assets/images/aerodyne-logo.png';
  selectRow: any;
  columns: any[];
  data: any[];
  fields: any[];
  filter: any;

  scrollHeight: string = '20vh';
  rows: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  totalRecords: number = 0;
  loading: boolean = false;
  addButton: boolean = false;
  editButton: boolean = false;
  viewButton: boolean = false;
  deleteButton: boolean = false;
  kbsdeleteBtn: boolean = false;
  approveButton: boolean = false;
  openButton: boolean = false;
  showColFilters: boolean = false;
  downloadButton: boolean = false;
  csvExcelPdfButton: boolean = false;
  searchOptionOnly: boolean = false;
  enableRow: boolean = false;

  bulkUpload: boolean = false;

  exportColumns: any[];

  defaultDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    size: EDialogSize.LARGE,
    showCustomCloseButton: true,
    // keepInViewport: false
  };

  formData: formType = {
    type: 'add',
  };
  bulkUploadButton: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.MEDIUM,
  };
  viewPanel1: IPanel = {
    showHeader: true,
  };
  isImport = false;
  uploadedFileName: string = '';
  excelImportResponse: any;
  validImportedData: any[] = [];
  organizationList: any;
  companyList: any;
  @ViewChild('messageModal') messageModal: ModalComponent;
  fileName: string = 'RequestorTemplate.xlsx'; // Replace with your actual file name
  downloadUrl: string = `../../../../assets/template/${this.fileName}`;
  bulkUploadResponse: any;
  allEmailExits: any;
  linkBaseUrl = environment.api_preview_url;
  isLazyLoading = false;
  private searchSubject = new Subject<string>(); 
  
  constructor(
    private sanitizer: DomSanitizer,
    private dataService: DataService,
    private apiService: ApiService,
    private http: HttpClient,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.columns = this.dataTable.columns;
    this.data = this.dataTable.data;
    this.filter = this.dataTable.filter;
    this.scrollHeight = `${this.dataTable.scrollHeight}vh`;
    this.totalRecords = this.dataTable.totalRecords;
    // this.rows = this.dataTable.rows;
    this.addButton = this.dataTable.addButton ? true : false;
    this.editButton = this.dataTable.editButton ? true : false;
    this.viewButton = this.dataTable.viewButton ? true : false;
    this.deleteButton = this.dataTable.deleteButton ? true : false;
    this.downloadButton = this.dataTable.downloadButton ? true : false;
    this.kbsdeleteBtn = this.dataTable.kbsdeleteBtn ? true : false;
    this.approveButton = this.dataTable.approveButton ? true : false;
    this.openButton = this.dataTable.openButton ? true : false;
    this.showColFilters = this.dataTable.showColFilters ? true : false;
    this.csvExcelPdfButton = this.dataTable.csvExcelPdfButton ? true : false;
    this.bulkUpload = this.dataTable.bulkUpload ? true : false;
    this.searchOptionOnly = this.dataTable?.searchOptionOnly ? true : false;
    this.enableRow = this.dataTable?.enableRow ? true : false;
    if (this.dataTable.heading) {
      this.defaultDialog.showHeader = true;
      this.defaultDialog.headerLabel = this.dataTable.heading;
    }
    this.defaultDialog.size =
      this.dataTable.size == 'medium'
        ? EDialogSize.MEDIUM
        : this.dataTable.size == 'small'
        ? EDialogSize.SMALL
        : EDialogSize.LARGE;
    if (this.dataTable.size == 'medium_1') {
      this.defaultDialog.size = EDialogSize.MEDIUM_1;
    }
    if (this.dataTable.hideOverflowY) {
      this.defaultDialog.hideOverflowY = true;
    }
    if (this.bulkUpload == true) this.getOrganisationsList();

    // After the table is rendered or data is loaded
    if(this.dataTable?.exportFileName && (this.dataTable?.exportFileName == 'Ticket Records' || this.dataTable?.exportFileName == 'Health Snapshot')){
        setTimeout(() => {
        const elements = document.getElementsByClassName('ui-table-wrapper');
        Array.from(elements).forEach((element) => {
            this.renderer.setStyle(element, 'overflow', 'hidden');
            this.renderer.setStyle(element, 'overflow-x', 'auto');
        });

        // Create a style element
        const style = document.createElement('style');
        style.innerHTML = `
            .ui-table-wrapper::-webkit-scrollbar {
                height: 8px !important; /* Adjust this value to change scrollbar height */
            }
        `;
        // Append the style element to the head of the document
        document.head.appendChild(style);
        }, 0);
     }

     this.searchSubject.pipe(
      debounceTime(500),           // Wait 500ms after the user stops typing
      distinctUntilChanged()        // Only emit if the value is different from the last
    ).subscribe(searchTerm => {
      this.ticketSearch.emit(searchTerm);  // Emit the debounced search term
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataTable']) {
      this.dataTable = changes['dataTable'].currentValue;
      this.columns = this.dataTable.columns;
      this.fields = this.columns.map((e) => e.field);
      this.data = this.dataTable.data;
      this.scrollHeight = `${this.dataTable.scrollHeight}vh`;
      this.totalRecords = this.dataTable.totalRecords;
      // this.rows = this.dataTable.rows;
      this.addButton = this.dataTable.addButton ? true : false;
      this.editButton = this.dataTable.editButton ? true : false;
      this.viewButton = this.dataTable.viewButton ? true : false;
      this.deleteButton = this.dataTable.deleteButton ? true : false;
      this.downloadButton = this.dataTable.downloadButton ? true : false;
      this.approveButton = this.dataTable.approveButton ? true : false;
      this.openButton = this.dataTable.openButton ? true : false;
      this.showColFilters = this.dataTable.showColFilters ? true : false;
      this.csvExcelPdfButton = this.dataTable.csvExcelPdfButton ? true : false;
      this.bulkUpload = this.dataTable.bulkUpload ? true : false;
      this.searchOptionOnly = this.dataTable.searchOptionOnly ? true : false;

      if (this.dataTable.heading) {
        this.defaultDialog.showHeader = true;
        this.defaultDialog.headerLabel = this.dataTable.heading;
      }
      this.defaultDialog.size =
        this.dataTable.size == 'medium'
          ? EDialogSize.MEDIUM
          : this.dataTable.size == 'small'
          ? EDialogSize.SMALL
          : EDialogSize.LARGE;
      if (this.dataTable.size == 'medium_1') {
        this.defaultDialog.size = EDialogSize.MEDIUM_1;
      }
      if(this.dataTable?.lazyLoad){
        this.pageSizeOptions = null;
        this.isLazyLoading = this.dataTable?.lazyLoad
      }
    }
    if (changes.closeDialog && changes.closeDialog.currentValue) {
      this.defaultDialog.display = false;
    }

  }

  // getTableDate(tableData){
  //   this.columns = tableData.columns;
  //   this.data = tableData.data;
  //   this.scrollHeight = tableData.scrollHeight;
  //   this.totalRecords = tableData.totalRecords;
  //   this.rows = tableData.rows;
  //   this.addButton = (tableData.addButton)?true:false
  //   this.editButton = (tableData.editButton)?true:false
  //   this.defaultDialog.size = (tableData.size=='medium')? EDialogSize.MEDIUM : EDialogSize.LARGE
  // }

  loadCarsLazy(event: LazyLoadEvent) {
    this.paginationChange.emit(event)
  }

  search(value) {}

  //Open Dialgue
  openDialogue(type, data) {
    this.defaultDialog.display = true;
    this.formData['type'] = type;
    if (type == 'edit') {
      this.formData.value = data;
    } else {
      this.formData.value = {};
    }
    this.OutputData.emit(this.formData);
    let userData = JSON.stringify(this.formData.value);
    localStorage.setItem('formData', btoa(userData));
  }

  selectedData(type, data) {
    this.OutputData.emit({ type, data });
  }

  /******************************Export Options ********************************************** */

  // Export as pdf
  async exportPdf() {
    this.exportColumns = await this.columns.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    let options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        let doc;
        let logoHeight = 0;

        // Add logo as base64 using extractImageFromDataUrl
        var logo = new Image();
        var rightSideLogo = new Image();

        if(this.dataTable?.pdfLayoutChange){
          doc = new jsPDF.default({
            orientation: 'landscape',
            unit: 'pt', 
            format: [1700,900], // Optional, you can set the paper size (e.g., 'a4', 'letter', etc.)
          });
          logoHeight = 50

          environment.client == 'kgis'
          ? (logo.src = 'assets/images/helpdesklogo.png')
          : (logo.src = 'assets/images/aerodyne-email-logo.png');
        }else{
          doc = new jsPDF.default(0, 0);

          environment.client == 'kgis'
          ? (logo.src = 'assets/images/helpdesklogo.png')
          : (logo.src = 'assets/images/aerodyne-green-logo.png');
        }
       
        const tableData = this.data;
        const _data = tableData.map((item) => {
          if(item["ticketResponseDescription"]){
              // Remove HTML tags
            let cleanedDescription = item["ticketResponseDescription"]?.replace(/<\/?[^>]+(>|$)/g, "");
          
            // Remove &nbsp; and other similar entities
            cleanedDescription = cleanedDescription.replace(/&nbsp;/g, " ").trim();
        
            return {
                ...item,
                "ticketResponseDescription": cleanedDescription
            };
          }else if(item["description"]){
            // Remove HTML tags
          let cleanedDescription = item["description"]?.replace(/<\/?[^>]+(>|$)/g, "");
        
          // Remove &nbsp; and other similar entities
          cleanedDescription = cleanedDescription.replace(/&nbsp;/g, " ").trim();
      
          return {
              ...item,
              "description": cleanedDescription
          };
        }else{
            return {
              ...item,
              "description": ''
          };
          }
          
      });

        environment.client == 'kgis'
        ? (rightSideLogo.src = 'assets/images/helpdesklogo.png')
        : (rightSideLogo.src = 'assets/images/aerodyne-icon.png');

        if (_data.length > 0) {
          _data.forEach(function (val, index) {
            const date = new Date(parseInt(val.createdAt));
            val['createdAt'] = date
              .toLocaleDateString('en', options as any)
              .toString();
          });
         let isPdfLayoutChange = this.dataTable?.pdfLayoutChange
         let topHeading = this.dataTable?.exportFileName
          // Add autoTable with header, data, and page number configuration
          doc.autoTable(this.exportColumns, _data, {
            startY: this.dataTable?.pdfLayoutChange ? 30 + logoHeight : 30,  // Adjust the starting Y position based on your logo height
            styles: {
              lineColor: [0.7, 0.7, 0.7], // Black color for borders
              lineWidth: 0.2, // Border width
              overflow: 'linebreak', // Enable word wrapping
              cellPadding: 5,
              minCellWidth: 72,
            },
            addPageContent: function (data) {
              if(isPdfLayoutChange){
                  // Add heading text at the top center
                  const headingText = topHeading +' '+ 'Reports';
                  const headingFontSize = 16; // Adjust as needed
                  doc.setFontSize(headingFontSize);
                  const pageWidth = doc.internal.pageSize.width;
                  const headingTextWidth = doc.getTextWidth(headingText);
                  
                  // Position heading text at the center
                  doc.text(
                    headingText,
                    (pageWidth - headingTextWidth) / 2, // Centered horizontally
                    30 // Adjust Y position as needed
                  );

                  // Add page number at the bottom right corner
                  doc.setFontSize(12);
                  const pageHeight = doc.internal.pageSize.height;
                  const pagePadding = 20; // Padding from the bottom and right edges
                  const text = 'Page ' + data.pageNumber;
                  const textWidth = doc.getTextWidth(text);
                  doc.text(
                    text,
                    pageWidth - textWidth - pagePadding, // X position
                    pageHeight - pagePadding // Y position
                  );

                  // Add logo at the top left corner
                  doc.addImage(logo, 'PNG', 10, 10, 130, 40);

                  // Add another icon at the top right end
                  const icon = rightSideLogo; // Replace with your actual icon
                  const iconWidth = 30; // Adjust as needed
                  const iconHeight = 30; // Adjust as needed
                  doc.addImage(icon, 'PNG', pageWidth - iconWidth - 10, 10, iconWidth, iconHeight); // Position at top right

                  // Update startY for the next page
                  data.settings.margin.top = 30 + 40;
                }else{
                  doc.setFontSize(8);
                  doc.text(
                    'Page ' + data.pageNumber,
                    doc.internal.pageSize.width - 30,
                    10
                  );
    
                  // Add logo at the top left corner
                  doc.addImage(logo, 'PNG', 10, 10, 30, 10);
    
                  // Update startY for the next page
                  data.settings.margin.top = 30 + logoHeight;
                }
            },
            didDrawPage: function (data) {
              // Update the logo height after the first page
              if (data.pageNumber === 1) {
                logoHeight = 10; // Update this value based on the actual height of your logo
              }
            },
          });
        } else {
          // If no data, display a message
          doc.addImage(logo, 'PNG', 10, 10, 130, 40);

          // Text content
          var text = 'No data found';

          // Set the font size and style
          doc.setFontSize(14);
          doc.setFont('helvetica', 'normal');

          // Get the width of the text
          var textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          // Calculate the x-coordinate for centering the text
          var xCoordinate = (doc.internal.pageSize.width - textWidth) / 2;

          // Position the text at (xCoordinate, 30)
          doc.text(text, xCoordinate, 30);
        }

        doc.save(this.dataTable?.exportFileName ? this.dataTable?.exportFileName : this.dataTable?.menuHeading);
      });
    });
    this.tableRefresh.emit('refresh')
  }

  // Export as excel
  exportExcel() {
    let options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    import('xlsx').then((xlsx) => {
      const _data = this.data;
      _data.forEach(function (val, index) {
        const date = new Date(parseInt(val.createdAt));
        const updateDate = new Date(parseInt(val.updatedAt));
        if (val.hasOwnProperty('organization'))
          val['organization'] = val?.organization?.name ?? '';
        if (val.hasOwnProperty('salutation'))
          val['salutation'] = val?.salutation?.name ?? '';
        val['createdAt'] = !isNaN(date.getTime())
          ? date.toLocaleDateString('en', options as any).toString()
          : ''; // check Invalid Date
        val['updatedAt'] = !isNaN(updateDate.getTime())
          ? updateDate.toLocaleDateString('en', options as any).toString()
          : ''; // check Invalid Date
      });
      let aux;
      if (this.filter == 'ticket') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          ticketId: el.ticketId,
          customerName: el.customerName,
          customerLastName: el.customerLastName,
          city: el.city,
          phoneNumber: el.phoneNumber,
          language: el.language,
          dueDate: el.dueDate,
          source: el.source,
          description: el.description,
          actualResponseTime: el.actualResponseTime,
          actualRestorationTime: el.actualRestorationTime,
          actualRestorationtime: el.actualRestorationTime,
          slaRestorationTime: el.slaRestorationTime,
          slaExtendedDuration: el.slaExtendedDuration,
          showSeekApprovel: el.showSeekApprovel,
          sourcetype: el.sourcetype,
          country: el.country,
          clientId: el.clientId,
          enquiryStatus: el.enquiryStatus,
          callContact: el.callContact,
          verbalConsent: el.verbalConsent,
          sendToDP: el.sendToDP,
          channel: el.channel,
          serviceType: el.service_type,
          assignedTo: el.assignedTo,
          currentStatus: el.currentStatus,
          paymentStatus: el.paymentStatus,
          ticketStatus: el.ticketStatus,
        }));
      } else if (this.filter == 'agent') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          serviceName: el.service_name,
          agentName: el.fullname,
          lastLogin: el.lastLogin,
          managerName: el.managerName,
          supervisorName: el.supervisorName,
          mobile: el.mobile,
          designationName: el.designationName,
          vicidialAgentCampaign: el.vicidialAgentCampaign,
          active: el.active,
          call_status: el.call_status,
          loggedIn: el.loggedIn,
          activeStatus: el.activeStatus,
          ticketCnt: el.ticketCnt,
          avgTime: el.avgTime,
          closeCnt: el.close_cnt,
          openCnt: el.open_cnt,
          assignCnt: el.assign_cnt,
          closePer: el.close_per,
        }));
      } else if (this.filter == 'ticket-aging') {
        aux = _data.map((el) => ({
          serviceName: el.service_name,
          ticketCnt: el.ticketCnt,
          avgTime: el.avgTime,
          openCnt: el.open_cnt,
          closeCnt: el.close_cnt,
          closePer: el.close_per,
        }));
      } else if (this.filter == 'call-report') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          ticketId: el.ticket_id,
          userName: el.userName,
          customerName: el.customerName,
          ticketDate: el.ticket_Date,
          callId: el.callId,
          dispositionTime: el.dispositionTime,
          phoneNumber: el.phoneNumber,
          agentId: el.agentId,
          callStartTime: el.callStartTime,
          callEndTime: el.callEndTime,
          Date: el.date,
          seconds: el.seconds,
          callRecordingId: el.callRecordingId,
          callRecordingFileName: el.callRecordingFileName,
          callType: el.callType,
          disposition: el.disposition,
          transferType: el.transferType,
          transferedTo: el.transferedTo,
          user: el.user,
          afterCallHours: el.afterCallHours,
        }));
      } else if (this.filter == 'email-report') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          ticketId: el.ticket_id,
          medium: el.medium,
          subject: el.subject,
          userName: el.userName,
          emailType: el.direction,
          fromId: el.from,
          to: el.to,
        }));
      } else {
        if(this.dataTable?.exportFileName && (this.dataTable?.exportFileName == 'Ticket Records' 
          || this.dataTable?.exportFileName == 'Health Snapshot'
          || this.dataTable?.exportFileName == 'Asset Details')){
          let columnData = this.dataTable.columns
          const FormattedData = _data.map(el => {
            let mappedObj = {};
            columnData.forEach(col => {
                mappedObj[col.header] = el[col.field];
            });
            return mappedObj;
          });
     
        const updatedData = FormattedData.map((item) => {
          if(item["Ticket Response Description"]){
              // Remove HTML tags
            let cleanedDescription = item["Ticket Response Description"]?.replace(/<\/?[^>]+(>|$)/g, "");
          
            // Remove &nbsp; and other similar entities
            cleanedDescription = cleanedDescription.replace(/&nbsp;/g, " ").trim();
        
            return {
                ...item,
                "Ticket Response Description": cleanedDescription
            };
          }else{
            return item;
          }
          
      });
        aux = updatedData;
        
        }else{
          aux = _data;
        }
      }

      const worksheet = xlsx.utils.json_to_sheet(aux);
      var wscols = [
        { wch: 26 },
        { wch: 26 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      worksheet['!cols'] = wscols;

      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.filter);
      this.tableRefresh.emit('refresh')
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        (this.dataTable?.exportFileName ? this.dataTable?.exportFileName : this.dataTable?.menuHeading) +
          '_export_' +
          new Date().getTime() +
          EXCEL_EXTENSION
      );
    });
  }

  // filter(value, field, dt) {
  //   dt.filter(value.statusText, 'year', 'equal')
  // }

  // dataTable: IDataTable = {
  //   columns: [
  //     { field: 'brand', header: 'brand' },
  //     { field: 'lastYearSale', header: 'lastYearSale' },
  //     { field: 'thisYearSale', header: 'thisYearSale' },
  //     { field: 'lastYearProfit', header: 'lastYearProfit' },
  //     { field: 'thisYearProfit', header: 'thisYearProfit' }
  //   ],
  //   data: [
  //     { brand: 'Apple', lastYearSale: '51%', thisYearSale: '40%', lastYearProfit: '$54,406.00', thisYearProfit: '$43,342' },
  //     { brand: 'Samsung', lastYearSale: '83%', thisYearSale: '96%', lastYearProfit: '$423,132', thisYearProfit: '$312,122' },
  //     { brand: 'Microsoft', lastYearSale: '38%', thisYearSale: '5%', lastYearProfit: '$12,321', thisYearProfit: '$8,500' },
  //     { brand: 'Philips', lastYearSale: '49%', thisYearSale: '22%', lastYearProfit: '$745,232', thisYearProfit: '$650,323,' },
  //     { brand: 'Song', lastYearSale: '17%', thisYearSale: '79%', lastYearProfit: '$643,242', thisYearProfit: '500,332' },
  //     { brand: 'LG', lastYearSale: '52%', thisYearSale: ' 65%', lastYearProfit: '$421,132', thisYearProfit: '$150,005' },
  //     { brand: 'Sharp', lastYearSale: '82%', thisYearSale: '12%', lastYearProfit: '$131,211', thisYearProfit: '$100,214' }
  //   ],
  //   scrollHeight: 100,
  //   rows: 10,
  //   totalRecords: 10,
  //   addButton : false,
  //   editButton : false
  // };

  confirmDelete(event) {
    if (event) {
      this.selectedData('delete', this.selectRow);
    } else {
    }
    this.selectRow = null;
  }

  deleteEvent(event) {
    this.selectRow = event;
    this.confirmDialog.open({
      message: 'Are you sure, you want to delete the record?',
      rejectLabel: 'No',
      confirmLabel: 'Yes',
      imgSrc: '../../../../assets/images/confirm.svg',
    });
  }
  downloadEvent(fileUrl) {
    // if (fileUrl.startsWith('/')) {
    //   fileUrl = fileUrl.substring(1);
    // }
    window.open(`${environment.api_preview_url}${fileUrl}`, '_blank');
  }

  customDelete(event) {
    this.selectRow = event;
    const message = event.knowledgeBaseLable;
    let htmlContent = `<span style="color: red;"> ${message} </span>`;
    this.confirmDialog.open({
      message: this.sanitizer.bypassSecurityTrustHtml(
        `Are you sure, Including ${htmlContent} should be deleted?`
      ),
      rejectLabel: 'No',
      confirmLabel: 'Yes',
      imgSrc: '../../../../assets/images/confirm.svg',
    });
  }

  handleChange(element, event) {
    this.OutputData.emit({
      isStatus: element.checked,
      type: 'edit',
      value: event,
    });
  }

  handleRow(rowData: any, columnData: any) {
    if (this.enableRow) {
      let payload = {};
      payload['row'] = { ...rowData };
      payload['column'] = { ...columnData };
      this.rowEmitter.emit(payload);
    }
  }

  openCreateTicket() {
    this.bulkUploadButton.display = true;
    this.validImportedData = [];
    this.validImportedData = [];
    this.bulkUploadResponse = '';
  }

  getOrganisationsList(): void {
    this.apiService.get('organization/get-all').subscribe(
      (result) => {
        if (result.data) {
          let responseData = result.data.organizations;
          this.organizationList = responseData.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        }
      },
      (error) => {
        this.messageModal.errorDialog(error);
      }
    );
  }
  onImportFileChange(event: any) {
    const fileInput = event.target;
    const file = fileInput.files[0];
    this.isImport = false;

    const mandatoryFieldsArray = [
      'Salutation',
      'First Name',
      'Last Name',
      'Country Code',
      'Phone Number',
      'Email',
      'Company Name',
    ];
    if (file) {
      this.uploadedFileName = file?.name;
      this.dataService
        .excelToJson(file, mandatoryFieldsArray)
        .then((data) => {
          let excelResponse = data;
          let validData = data.data;
          let invalidData = data.invalidRows;
          this.excelImportResponse = data;

          validData = validData.map((obj) => ({
            salutation: {
              name: obj.salutation,
              code: obj.salutation.toUpperCase(),
            },
            name: obj.first_name ? obj.first_name : '',
            lastName: obj.last_name ? obj.last_name : '',
            phoneNumber: obj.phone_number ? obj.phone_number : '',
            email: obj.email ? obj.email : '',
            organization: this.getOrganisation(obj.company_name),
            code: obj.country_code ? '+' + obj.country_code : '',
            status: true,
          }));
          this.validImportedData = validData;
          this.isImport = true;
          fileInput.value = '';
        })
        .catch((error) => {
          this.messageModal.infoDialog(error?.message);
          fileInput.value = '';
        });
    }
  }

  getOrganisation(companyName: string) {
    const organization = this.organizationList.find(
      (org) => org.name === companyName
    );
    return organization ? { id: organization.id, name: organization.name } : {};
  }
  bulkUploadRequestor() {
    const contactInfos = { contactInfos: this.validImportedData };

    this.apiService.post('contact-info/bulk-insert', contactInfos).subscribe(
      (response) => {
        this.bulkUploadResponse = response;
        this.messageModal.warnDialog(response.error.message);
      },
      (error) => {
        console.log('Error uploading', error);
      }
    );
  }

  downloadFile() {
    this.http
      .get(this.downloadUrl, { responseType: 'blob' })
      .subscribe((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = this.fileName;
        link.click();
        window.URL.revokeObjectURL(downloadUrl); // Revoke temporary URL after download
      });
  }

  onSort(event:any){
    this.OutputData.emit({
      type: 'sort',
      value: event,
    });
  }

  ticketRecordSearch(event:any){
    this.searchSubject.next(event);
  }
 
}
