import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { socketService } from 'src/app/services/socket.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { CanComponentDeactivate } from 'src/app/models/services/can-deactivate.guard';

@Component({
  selector: 'app-ticket-approval',
  templateUrl: './ticket-approval.component.html',
  styleUrls: ['./ticket-approval.component.css'],
})
export class TicketApprovalComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('messageModal') messageModal: ModalComponent;
  // @ViewChild('resolveFile') resolveFile: ElementRef;
  feedbackForm: FormGroup;
  updateTimeForm: FormGroup;
  showHide: boolean = false;
  showFeedbackScreen: boolean = false;
  showThankYouScreen: boolean = false;
  showRejectScreen: boolean = false;
  questionsList: any[] = [];

  token: any;
  validateToken$: Subscription;
  recordList = [];
  user_name: string = '';
  tokenUser: string = '';
  ticketcurrentStatus: any;
  timelineSocket$: Subscription;
  customerDetails: any;
  tokenDetails: any;
  alreadySubmitStatus: boolean = true;
  approvalMessage: any;
  submitComment$: Subscription;
  getTicketStatusList$: Subscription;
  ticketStatusList: any;
  ticketStatusId: any;
  rejectNotification$: Subscription;
  checkRejectStatus$: Subscription;
  ticketStatus: any = {};
  approvalLevel:string='';
  approvalStatusCheck$: Subscription;
  changeManagementApproved: boolean = false;
  changeManagementApprovalForm: FormGroup;
  approveCode = 0;
  rejectCode = 0;
  usersApproved:string=''
  totalUsersCount = 0
  ApprovedUsersCount = 0
  isApprovalSubmit: boolean = false;
  aeroLogo = '../../../../assets/images/aerodyne-logo.png';
  attachment='';
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if(params?.approvalLevel){
        this.approvalLevel = params?.approvalLevel
        this.customerDetails = params;
        this.ticketStatus.approve = params?.appr;
        this.ticketStatus.reject = params?.rej;
        this.token = params?.token;
        this.attachment = params?.attachment ? params?.attachment : ''
      }else{
      this.token = params?.token;
      this.customerDetails = params;
      this.ticketStatus.approve = params?.APR;
      this.ticketStatus.reject = params?.REJ;
      }
      
    });
    this.feedbackForm = this.fb.group({
      questions: this.fb.array([]),
    });
    this.updateTimeForm = new FormGroup({
      comments: new FormControl('', [Validators.required]),
    });
    this.changeManagementApprovalForm = new FormGroup({
      comments: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    // this.getTokenDetails();
    if(this.approvalLevel == ''){
      this.getTicketStatusList();
    let data = {
      token: this.token,
    };
    this.checkRejectStatus$ = this.apiService
      .post('check-customer-timeline/' + this.customerDetails?.id, data)
      .subscribe((e) => {
        this.statusMessage = e;
      });
    }else{this.approvalStatusCheck();}
      
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (this.feedbackForm.invalid) {
      event.preventDefault();

      event.returnValue =
        'Are you sure you want to leave? Your changes may not be saved.';
      return event.returnValue;
    }
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Your logic to check for unsaved changes
    const unsavedChanges = this.feedbackForm.invalid; // For example, check if there are unsaved changes;
    if (unsavedChanges) {
      return confirm(
        'Are you sure you want to leave? Your changes will be lost.'
      );
    }
    return true;
  }

  isFeedbackFormDirtyAndInvalid(): boolean {
    return this.updateTimeForm.dirty && this.updateTimeForm.invalid;
  }
  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //   event.preventDefault();
  //   event.returnValue = false;
  // }

  approveStatus() {
    let data = {
      token: this.token,
    };
    this.getQuestionList();
    this.validateToken$ = this.apiService
      .post('ticket/update-by-customer', data)
      .subscribe((data) => {
        this.statusMessage = data;
        this.approvalMessage = data;
        this.tokenDetails = data?.data;
        this.alreadySubmitStatus = data?.feedBack;

        if (data?.status == true) {
          this.updateTicketStatus(this.ticketStatus?.approve);
          this.showHide = data?.feedBack;
          this.showFeedbackScreen = data?.feedBack;
        } else {
          this.showHide = data?.feedBack;
          this.showFeedbackScreen = data?.feedBack;
        }
      });
  }
  getTicketStatusList() {
    this.getTicketStatusList$ = this.apiService
      .get(`get-ticket-status-master?type=timeline`)
      .subscribe((response) => {
        this.ticketStatusList = response?.data;
      });
  }
  getQuestionList() {
    this.apiService.get('ticket/get-ticket-questions').subscribe((data) => {
      this.questionsList = data?.data;
      const questionControls = this.questionsList.map((question) => {
        return this.fb.group({
          question: [question?.question],
          options: [question?.options],
          answer: ['', Validators.required],
          reason: [''],
        });
      });

      this.feedbackForm.setControl(
        'questions',
        this.fb.array(questionControls)
      );
    });
  }
  updateTicketStatus(statusId?: string) {
    let ticketStatus = this.ticketStatusList?.find(
      (data: any) => data?.status === 'Closed'
    );

    // this.ticketStatusId = ticketStatus ? ticketStatus.id : null;
    this.ticketStatusId = statusId ? statusId : null;
    let data = {
      statusId: this.ticketStatusId,
      ticketId: this.customerDetails?.id
        ? Number(this.customerDetails.id)
        : null,
    };
    this.apiService.post('v1/update-ticket-status', data).subscribe((e) => {
      if (e.status === true) {
        // this.showRejectScreen = true;
        // this.showThankYouScreen = false;
        // this.showHide = true;
        // this.showFeedbackScreen = false;
      } else {
        this.messageModal.errorDialog('unable to update ticket status');
      }
    });
  }
  statusMessage: string;
  onSubmit(): void {
    let data = {
      ticket: this.customerDetails?.id,
      emailId: this.customerDetails?.customerEmail,
      customerResponse: this.feedbackForm.value?.questions,
    };

    this.apiService
      .post('ticket/update-ticket-questions-survey', data)
      .subscribe((data) => {
        if (data.status === true) {
          this.showThankYouScreen = true;
          this.showHide = true;
          this.showFeedbackScreen = false;
        }
      });
  }
  onReject() {
    let data = {
      token: this.token,
    };
    this.checkRejectStatus$ = this.apiService
      .post('check-customer-timeline/' + this.customerDetails?.id, data)
      .subscribe((e) => {
        this.statusMessage = e;

        if (e?.status == true) {
          this.showRejectScreen = true;
          this.showThankYouScreen = false;
          this.showFeedbackScreen = false;
          this.showHide = e?.status;
        } else {
          this.showHide = e?.status;
          this.alreadySubmitStatus = e?.status;
        }
      });
  }

  onRejectSubmit() {
    let ticketStatus = this.ticketStatusList?.find(
      (data) => data?.status === 'Work In Progress'
    );
    // this.ticketStatusId = ticketStatus ? ticketStatus.id : null;
    this.ticketStatusId = this.ticketStatus?.reject
      ? this.ticketStatus?.reject
      : null;

    let data = {
      comment: this.updateTimeForm.value.comments,
      token: this.token,
      ticketStatusId: this.ticketStatusId,
      approvalStatus: 'REJECT',
    };

    if (!data.comment) {
      this.messageModal.warnDialog(
        'Please add your comments before submitting'
      );
      return;
    }
    this.submitComment$ = this.apiService
      .put('update-customer-timeline/' + this.customerDetails?.id, data)
      .subscribe((e) => {
        this.updateTimeForm.reset();
        if (e?.status == true) {
          this.rejectNotifications();
        } else {
          this.showHide = e?.feedBack;
          this.showFeedbackScreen = e?.feedBack;
        }
      });
  }

  rejectNotifications() {
    let notification = {
      ticketId: this.customerDetails?.id,
      ticket: this.customerDetails?.ticketId,
    };
    this.rejectNotification$ = this.apiService
      .post('resolved-notification', notification)
      .subscribe((e) => {
        if (e.status == true) {
          this.showRejectScreen = false;
          this.showThankYouScreen = true;
        }
      });
  }

  approvalStatusCheck(){
    let body={
      'ticketId': this.customerDetails?.ticketId,
      'approvalLevel':this.approvalLevel,
    }
    this.approvalStatusCheck$ = this.apiService
      .post('chg-mngmnt/approval-status-check?token='+this.token, body)
      .subscribe((e) => {
        let responseData = e?.data;
       
        if (responseData?.approvalLevel == 'L1') {
          this.usersApproved = responseData?.approvedBy
          if(responseData?.approvalStatus){
            this.changeManagementApproved = true;
          }
        }
        if (responseData?.approvalLevel == 'L2' || responseData?.approvalLevel == 'L3') {
          this.usersApproved = e?.data?.approvedBy
          this.ApprovedUsersCount = responseData?.got;
          this.totalUsersCount = responseData?.required;
          if(e?.message == 'Already approved by you' || responseData?.approvalStatus){
            this.changeManagementApproved = true;
          }
        }
      });
  }

  ChangeManagementApprove(action:string){
    this.isApprovalSubmit = true;
    let statusId;
    if(action == 'approve'){
      statusId = this.ticketStatus.approve
    }else{
      statusId = this.ticketStatus.reject
    }
    let body={
      'ticketId': this.customerDetails?.ticketId,
      'approvalLevel':this.approvalLevel,
      'statusId':+statusId,
      'comments': this.changeManagementApprovalForm.value.comments,
      'attachment': this.attachment ? JSON.parse(this.attachment) : ''
    }
    
    this.approvalStatusCheck$ = this.apiService
      .post('chg-mngmnt/update-approval-status?token='+this.token, body)
      .subscribe((res) => {
        if (res.status == true) {
          this.isApprovalSubmit = false;
          this.approvalStatusCheck();
          this.showThankYouScreen = true;
        }else{
          this.isApprovalSubmit = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.validateToken$) this.validateToken$.unsubscribe();
    if (this.submitComment$) this.submitComment$.unsubscribe();
    if (this.timelineSocket$) this.timelineSocket$.unsubscribe();
    if (this.getTicketStatusList$) this.getTicketStatusList$.unsubscribe();
    if (this.getTicketStatusList$) this.getTicketStatusList$.unsubscribe();
    if (this.rejectNotification$) this.rejectNotification$.unsubscribe();
    if (this.checkRejectStatus$) this.checkRejectStatus$.unsubscribe();
  }
}
