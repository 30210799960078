import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PrimengModule } from './modules/primeng/primeng.module';
import { SharedModule } from './modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoaderService } from './services/loader.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './services/http-interceptor.service';
import { ModalComponent } from './modules/shared/modal/modal.component';
import { ModalViewTimelineComponent } from './modules/shared/modal-view-timeline/modal-view-timeline.component';
// import { StripHtmlPipe } from './directives/htmlToText.directive';
// import { UsersModule } from './modules/users/users.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { JwtService } from './services/jwt.service';
import { AssetManagementMasterApiService } from '../app/services/asset-management-master-api.service';
import { AssetManagementService } from '../app/services/asset-management.service';
import { documentUploadServices } from './services/document-upload.service';
import { FeedbacksComponent } from './modules/feedbacks/feedbacks.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { TicketRecordsComponent } from './modules/ticket-records/ticket-records.component';
import { DatePipe } from '@angular/common';
const config: SocketIoConfig = {
  url: environment.socket_url,
  options: { query: { user_token: atob(localStorage.getItem('Token')) } },
};

@NgModule({
  declarations: [
    AppComponent,
    FeedbacksComponent,
    SettingsComponent,
    TicketRecordsComponent,
    // StripHtmlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PrimengModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    // UsersModule
  ],
  providers: [
    LoaderService,
    ModalComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    ModalViewTimelineComponent,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
