import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Editor } from 'primeng';

@Component({
  selector: 'editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
})
export class EditorComponent implements OnInit {
  @ViewChild(Editor) editor: Editor;
  @Input() editorHeight;
  @Input() editorMinHeight;
  @Input() innerText: string;

  @Output() text = new EventEmitter();
  @Output() textOnly = new EventEmitter();
  textVal: any;
  constructor() {}

  ngOnInit(): void {}

  clearText() {
    this.editor.writeValue('');
  }

  setText(text) {
    this.editor.writeValue(text);
  }

  emitText(e) {
    this.getTextOnly(e.textValue);
    this.text.emit(e.htmlValue);
  }

  getTextOnly(val) {
    this.textOnly.emit(val);
  }
}
