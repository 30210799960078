import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface IUploadDocumentList {
  status: string;
  data: DocumentList[];
}

export interface DocumentList {
  Id: number;
  document_name: string;
  document_type: string;
  file_name: string;
  file_type: string;
  file_size: string;
  description: string;
  tags: string;
  document_status: DocumentStatus;
  file_location: string;
  module: string;
  created_by: string;
  created_date: string;
  parent_id: number;
  asset_id: number;
  updated_by: any;
  updated_at: any;
  document_version: string;
  document_owner: string;
  document_expiry_date: string;
}

export interface DocumentStatus {
  type: string;
  data: number[];
}

@Injectable({ providedIn: 'root' })
export class documentUploadServices {
  private baseUrl: string;
  constructor(private apiService: ApiService, private http: HttpClient) {
    this.baseUrl =
      environment.api_url === 'https://api-aerodynehelpdesk.wait-wise.com/'
        ? environment.api_url + 'assets/'
        : environment.api_url;
  }

  onFileUpload(formData: FormData, query?: string): Observable<any> {
    // const fileUploadUrl =
    //   environment.api_url === 'https://api-aerodynehelpdesk.wait-wise.com/'
    //     ? environment.api_url
    //     : environment.api_url_upload;
    // let url = `${fileUploadUrl}upload-file`;
    // query && (url = ` ${url}?moduleName=${query}`);

    let url = environment.api_url_upload;
    query && (url = ` ${url}upload-file?moduleName=${query}`);

    return this.http.post(`${url}`, formData).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  onDocumentUpload(uploadData): Observable<any> {
    return this.http.post(`${this.baseUrl}documents/edit`, uploadData).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  onUploadImg(uploadData): Observable<any> {
    return this.http.post(`${this.baseUrl}img`, uploadData).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  getAllDocumentList(): Observable<Object> {
    return this.http.get(`${this.baseUrl}documents/get-all?limit=20`);
  }

  getDocumentListBy(
    moduleName: string,
    parentId: number,
    assetId: number
  ): Observable<IUploadDocumentList> {
    const query = `documents/get-asset-documents?asset_id=${assetId}&parent_id=${parentId}&asset_module=${moduleName}&document_status=${1}`;

    return this.http.get<IUploadDocumentList>(`${this.baseUrl}${query}`);
  }

  deleteDocument(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}documents/delete`, payload).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }
}
