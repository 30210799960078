import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Observable, throwError, BehaviorSubject } from 'rxjs';

// jwt service
import { JwtService } from './jwt.service';

// user service
import { UserService } from './user.service';

// Loader service
import { LoaderService } from './loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { socketService } from './socket.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    public loaderService: LoaderService,
    private router: Router,
    private socketService: socketService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // get token
    const token = this.jwtService.getToken();

    var res = req.url.match(/103.21.34.19/g);
    if (res != null) {
      //   Access-Control-Allow-Headers': 'Content-Type',
      // 'Access-Control-Allow-Methods': 'GET',
      // 'Access-Control-Allow-Origin': '*'
      return next.handle(req);
    }

    // token present set authorization header and update user session time
    if (token) {
      // this.userService.updateSessionTime();
      this.loaderService.show();
      return next
        .handle(
          this.addToken(req, token)
        )
        .pipe(finalize(() => this.loaderService.hide()))
        .pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 402) {
              this.userService.setApiError(error.error.message);
              localStorage.clear();
              this.router.navigate(['/auth']);
              this.socketService.disconnect();
            }
            if (error instanceof HttpErrorResponse && error.status === 403) {
              // return this.handle401Error(request, next);
              // localStorage.clear();
              // this.router.navigate(['/auth']);
              // this.socketService.disconnect();
              return this.handle403Error(req, next);
            } else {
              return throwError(error);
            }
          })
        );
    }
    return next.handle(req);
  }

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });
  }


  private handle403Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.jwtService.getNewToken().pipe(
        switchMap((res: any) => {
          const token = res.token;
          this.isRefreshing = false;
          this.jwtService.saveToken(token);
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(req, token));
        }),
        catchError(error => {
          this.isRefreshing = false;
          this.userService.setApiError(error.error.message);
          localStorage.clear();
          this.router.navigate(['/auth']);
          this.socketService.disconnect();
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        })
      );
    }
  }
}
