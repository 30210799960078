import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.css']
})
export class FeedbacksComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  today = new Date();
  ticketInfoSearch = '';
  feedbacks: any[] = []
  ticketDetailsArray: any[] = [];
  overallFeedbacksArray: any[] = [];
  selectedListItem:number;
  selectedTicket:any=null;
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.getFeedbacks();
  }

  SearchTicketInfo() {
    this.getFeedbacks();
  }

  getFeedbacks() {
    const fromDate = new Date(this.fromDate).getTime();
    const toDate = new Date(this.toDate).getTime();

    this.apiService.get(`ticket/questions-survey/all?fromDate=${fromDate}&todate=${toDate}&searchBy=${this.ticketInfoSearch}`).subscribe((response) => {
      if (response) {
        let responseData = response?.data;
        const parsedData = responseData.map(item => {
          return {
            ...item,
            customerResponse: JSON.parse(item.customerResponse)
          };
        });
        this.overallFeedbacksArray = parsedData;
        const extractedData = parsedData.map(item => ({
          id: item.id,
          emailId: item.emailId,
          ticketName: item.ticket.ticketId,
          ticketId: item.ticket.id,
          agent:item?.ticket?.assignedTo?.fullname
        }));
        this.ticketDetailsArray = extractedData
        if (this.ticketDetailsArray.length == 0) {
          this.feedbacks = [];
        }
        this.onTicketInfoSelect(this.ticketDetailsArray[0],0)
      }

    });
  }

  onTicketInfoSelect(ticket: any,index:number) {
    this.selectedListItem=index;
    this.selectedTicket= ticket;
    if (ticket?.id) {
      let filteredData = this.overallFeedbacksArray.find(item => item.id === ticket?.id);
      let customerActivities = filteredData?.customerResponse;
      const modifiedData = customerActivities?.map(item => {
        if (item.answer.includes("Please state the reason")) {
          item.answer = item.answer.replace("Please state the reason", "").trim();
        }
        return item;
      });
      this.feedbacks = modifiedData;
    }

  }

  navigate(ticket: any) {
    if(ticket){
      let ticketId = localStorage.setItem('ticketId', ticket?.ticketName);
      const url = `/dashboard/ticket-details/view/${ticket?.ticketId}`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.onload = () => {
          newTab.document.title = `${ticket?.ticketName} - Aerodyne Group - Drone Tech, Data Tech & Digital Transformation`;
        };
      }
    }
   
  }

}
