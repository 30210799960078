import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { options } from 'fusioncharts';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public http: HttpClient) {}

  // get method
  public get(
    path: string,
    params: HttpParams = new HttpParams(),
    headerOptions?: string | string[]
  ): Observable<any> {
    let headers = new HttpHeaders();
    if (headerOptions) {
      headers = new HttpHeaders().set('tag', headerOptions);
    }
    return this.http
      .get(`${environment.api_url}${path}`, { params, headers })
      .pipe(
        map((response) => response),
        catchError((error) => of(error))
      );
  }

  // put method
  public put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${environment.api_url}${path}`, body).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  // put method
  public patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(`${environment.api_url}${path}`, body).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  // post method
  public post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.api_url}${path}`, body).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  public postFile(moduleName: string, body: Object = {}): Observable<any> {
    return this.http
      .post(`${environment.api_url_upload}upload-file?moduleName=${moduleName}`, body)
      .pipe(
        map((response) => response),
        catchError((error) => of(error))
      );
  }

  // Multiple file upload
  public postMultipleFiles(moduleName: string, body: Object = {}): Observable<any> {
    return this.http
      .post(`${environment.api_url_upload}upload-files?moduleName=${moduleName}`, body)
      .pipe(
        map((response) => response),
        catchError((error) => of(error))
      );
  }

  // Ticket file delete
  public deleteFile(id: number) {
    return this.http
     .delete(`${environment.api_url_upload}delete-file/${id}`)
     .pipe(
        map((response) => response),
        catchError((error) => of(error))
      );
  }

  // appointment status post method
  public appt_post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(`${environment.appointment_api_url}${path}`, body)
      .pipe(
        map((response) => response),
        catchError((error) => of(error))
      );
  }

  // delete method
  public delete(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http.delete(`${environment.api_url}${path}`, { params }).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  public payment_post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.payment_url}${path}`, body).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }

  public hash_post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.hash_url}${path}`, body).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }
  // appointment status post method
  public vicidial_post(path: string, body: Object = {}): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'POST');
    headers.append('Access-Control-Allow-Origin', '*');
    return this.http
      .post(`${environment.vicidial_api_url}${path}`, body, {
        headers: headers,
        responseType: 'text',
      })
      .pipe(
        map((response) => response),
        catchError((error) => of(error))
      );
  }

  public validateVicidalUrl(path: string) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'POST');
    headers.append('Access-Control-Allow-Origin', '*');
    return this.http.head(`${path}`);
    //   .pipe(map(response => response), catchError(error => of(error)));
  }

  public shortenURL(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.shorten_url_local}${path}`, body).pipe(
      map((response) => response),
      catchError((error) => of(error))
    );
  }
}
