import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import {
  EPaymentUserType,
  IPriceData,
  IPaymentInput,
} from '../../../models/modules/shared/payment.model';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  @Input() paymentBasicInfo: IPaymentInput;

  fetchData$: Subscription;
  createPayment$: Subscription;

  priceFormGroup: FormGroup;
  pricedData: IPriceData = {
    ticket_id: '',
    created_at: '',
    invoice_no: '',
    description: '',
    price: null,
    discount: null,
    billed_amount: null,
    ticket_DB_id: '',
  };

  showSubmitButton: boolean = true;
  showProgress: boolean = false;

  max_note_char: number = 15;

  type: EPaymentUserType;
  refType = EPaymentUserType;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.priceFormGroup = this.fb.group({
      ticket_id: [''],
      price: [null, Validators.required],
      discount: [null],
      billed_amount: [null, Validators.required],
      description: ['', Validators.required],
      notes: [''],
      tax_description: ['', Validators.required],
      tax_amount: [null, Validators.required],
    });
  }

  async ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes && changes['paymentBasicInfo'].currentValue)
      this.updateBasicInfo(changes['paymentBasicInfo'].currentValue);
  }

  async updateBasicInfo(paymentInfo: IPaymentInput) {
    this.type = null;
    this.pricedData.ticket_id = '';
    this.pricedData.invoice_no = '';

    if (paymentInfo.type) this.type = paymentInfo.type;
    if (paymentInfo.ticket_id)
      this.pricedData.ticket_id = paymentInfo.ticket_id;
    if (paymentInfo.invoice) this.pricedData.invoice_no = paymentInfo.invoice;
    if (paymentInfo.id) this.pricedData.ticket_DB_id = paymentInfo.id;

    this.showSubmitButton = this.type == this.refType.USER ? true : false;

    this.getFormData();
  }

  async getFormData() {
    let fetchUrl =
      this.type == this.refType.CUSTOMER
        ? `api/appointment/get-invoice/${this.pricedData.invoice_no}`
        : `get-ticket/${this.pricedData.ticket_DB_id}`;

    this.showProgress = true;
    this.fetchData$ = await this.apiService
      .get(fetchUrl)
      .subscribe((response) => {
        this.showProgress = false;
        if (!response.status) return;

        if (this.type == this.refType.CUSTOMER)
          this.pricedData = this.framePriceData(response['data'][0][0]);

        if (this.type == this.refType.USER)
          this.pricedData = this.framePriceData(response['data']);
        this.priceFormGroup.reset();
        this.priceFormGroup.updateValueAndValidity();
        this.priceFormGroup.patchValue(this.pricedData);
      });
  }

  framePriceData(response) {
    if (this.type == this.refType.CUSTOMER) {
      const _invoice_no = this.pricedData.invoice_no;
      return { ...response, invoice_no: _invoice_no };
    }
    if (this.type == this.refType.USER) {
      const _ticket_id = this.pricedData.ticket_id;
      const _id = this.pricedData.ticket_DB_id;

      // Customer info
      let customer = {
        name: response['customerName'],
        contact: response['phoneNumber'],
        email: response['customerEmail'],
      };

      return {
        ticket_id: _ticket_id,
        customer: customer,
        type: 'link',
        expire_by: '',
        projectCode: '',
        callback_url: '',
        callback_method: 'get',
        ticket_DB_id: _id,
      };
    }
  }

  getControllerValue(controller) {
    return this.priceFormGroup.get(controller).value;
  }

  updateBilledAmount() {
    const price = this.priceFormGroup.get('price').value;
    const discount = this.priceFormGroup.get('discount').value;
    const tax = this.priceFormGroup.get('tax_amount').value;
    const _bill_amount = price - discount + tax;
    this.priceFormGroup.get('billed_amount').setValue(_bill_amount);
  }

  async createPayment() {
    if (this.priceFormGroup.invalid) return;

    let _body = { ...this.pricedData, ...this.priceFormGroup.value };
    _body['amount'] = _body['billed_amount'].toString();
    if (_body['price']) _body['price'] = _body['price'].toFixed(2);
    if (_body['discount']) _body['discount'] = _body['discount'].toFixed(2);
    if (_body['tax_amount'])
      _body['tax_amount'] = _body['tax_amount'].toFixed(2);
    if (_body['billed_amount'])
      _body['billed_amount'] = _body['billed_amount'].toFixed(2);
    _body['callback'] = environment.api_url + 'payment-callback-v1';
    _body['invoiceBaseUrl'] =
      environment.ui_base_url + 'view-payment?type=CUSTOMER&invoiceNo=';
    _body['ticket_id'] = _body['ticket_DB_id'];

    if (_body['ticket_DB_id']) delete _body['ticket_DB_id'];

    this.showProgress = true;
    this.createPayment$ = await this.apiService
      .payment_post(`/api/payment-checkout/create-payment-link-v1`, _body)
      .subscribe((response) => {
        this.showProgress = false;
        if (!response['status']) return;

        // this.modalService.successMessage(response['message'])
        this.showSubmitButton = false;
        this.pricedData.short_url = response['data']['short_url'];
        this.pricedData.invoice_url = response['data']['invoice_url'];
        this.pricedData.created_at = response['data']['created_at'];
        this.pricedData.invoice_no = response['data']['id'];
      });
  }

  makePayment() {
    window.open(this.pricedData.short_url, '_self');
  }
  openInvoice() {
    window.open(this.pricedData.invoice_url, '_self');
  }

  createNewInvoice() {
    this.priceFormGroup.reset();
    this.priceFormGroup.updateValueAndValidity();
    const refreshData = {
      ticket_id: this.pricedData.ticket_id,
      created_at: '',
      invoice_no: '',
      description: '',
      price: null,
      discount: null,
      billed_amount: null,
    };
    this.pricedData = { ...refreshData };
    this.priceFormGroup.patchValue(this.pricedData);
    this.showSubmitButton = true;
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.createPayment$) this.createPayment$.unsubscribe();
    if (this.fetchData$) this.fetchData$.unsubscribe();
  }
}
