import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangeComponent implements OnInit {
  selectedDates: Date[];
  selectedRange;
  selectedFromDate;
  selectedToDate;
  ranges;
  currentDate;
  label;
  @Output() datesChange = new EventEmitter();
  constructor() {
    const Today = moment().format('YYYY-MM-DD');
    const Yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
    const SevenDay = moment().add(-6, 'days').format('YYYY-MM-DD');
    const ThirtyDay = moment().add(-29, 'days').format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    const lastStartOfMonth = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const lastEndOfMonth = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    this.ranges = [
      { name: 'Today', from: Today, to: Today },
      { name: 'Yesterday', from: Yesterday, to: Yesterday },
      { name: 'Last 7 Days', from: SevenDay, to: Today },
      { name: 'Last 30 Days', from: ThirtyDay, to: Today },
      { name: 'This Month', from: startOfMonth, to: endOfMonth },
      { name: 'Last Month', from: lastStartOfMonth, to: lastEndOfMonth },
      { name: 'Custom Range', from: Today, to: Today },
    ];
  }

  ngOnInit(): void {}

  getLabel(): string {
    if (this.selectedDates && this.selectedDates.length > 1) {
      const from =
        this.selectedDates[0] === null
          ? ''
          : this.selectedDates[0].toDateString();
      const to =
        this.selectedDates[1] === null
          ? ''
          : this.selectedDates[1].toDateString();
      return from + ' - ' + to;
    } else {
      return 'Select Date Ranges';
    }
  }

  rangeChange(dates): any {
    this.selectedDates = [new Date(dates.from), new Date(dates.to)];
  }

  applyDate(): any {
    this.datesChange.emit(this.selectedDates);
  }
}
