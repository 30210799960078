<section id="template">
    <div class="p-grid ui-fluid">
        <div class="p-col-12 p-lg-4 p-m-auto p-md-6 p-p-0">
            <ng-container *ngIf="approvalLevel == ''">
                <div *ngIf="!showHide" id="feedback">
                    <div class="card">
                        <div class="approval-card-header">
                            <img src="../../../../assets/images/aerodyne-email-logo.png" class="logo-chart-screen"
                                alt="Aerodyne">
                        </div>
                        <div *ngIf="alreadySubmitStatus">
                            <div class="approval-card-body">
                                <h4 class="p-mb-3">Hi {{customerDetails?.customerName}},</h4>
                                <p class="p-my-2"><strong>Ticket ID : </strong> {{customerDetails?.ticketId}}</p>
                                <p class="description p-mb-4 p-mt-0">
                                    Please confirm your action.
                                </p>
                            </div>
                            <div class="approval-card-footer">
                                <div class="btnGrid">
                                    <button pButton type="button" class="btnBg ui-button-raised ui-button-success"
                                        (click)="approveStatus()" label="Approve"></button>
                                    <button pButton type="button" label="Reject"
                                        class="ui-button-raised ui-button-danger btnDanger" (click)="onReject()"></button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!alreadySubmitStatus">
                            <h4 class="p-mb-1">Hi {{customerDetails?.customerName}},</h4>
                            <p class="description p-mb-4">
                                {{statusMessage?.message}} - <strong>{{customerDetails?.ticketId}}</strong>
                            </p>
                            <p class="description p-mb-3 p-text-lg-center">
                                Should there be any further inquiries, please contact us at
                                <a href="mailto:helpdesk@aerodyne.group">helpdesk{{'@'}}aerodyne.group</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="showFeedbackScreen" id="feedback">
                    <div class="card cardView">
                        <div class="approval-card-header">
                            <img src="../../../../assets/images/aerodyne-email-logo.png" class="logo-chart-screen"
                                alt="Aerodyne">
                        </div>
                        <div class="approval-card-body">
                            <h4 class="p-mb-1">Hi {{customerDetails?.customerName}},</h4>
                            <p class="description p-mb-3">
                                {{questionsList[0]?.description}}
                            </p>
                            <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
                                <div class="questions">
                                    <ng-container formArrayName="questions">
                                        <ng-container
                                            *ngFor="let questionGroup of feedbackForm.get('questions').controls; let i = index">
                                            <div [formGroupName]="i" class="p-field p-mb-3">
                                                <h4 [for]="'feedback' + i" class="p-m-2 required-field">{{ i+1 }}. {{
                                                    questionGroup.get('question').value }}</h4>
                                                <div
                                                    *ngFor="let option of questionGroup.get('options').value; let j = index">
                                                    <input
                                                        [id]="'feedback' + i + '-' + j" type="radio"
                                                        [formControlName]="'answer'" [value]="option" required>
                                                    <!-- <label [for]="'feedback' + i + '-' + j">{{ option }}</label> -->
                                                    <div class="p-px-2 p-mt-2" *ngIf="questionsList[i]?.id == 5 && option == 'No.'">
                                                        <textarea placeholder="Please state the reason" id="reason" rows="2" pInputTextarea formControlName="reason"></textarea>
                                                    </div>
                                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input [id]="'feedback' + i + '-' + j" type="radio" [formControlName]="'answer'" [value]="option" required>
                                                    <label [for]="'feedback' + i + '-' + j">{{ option }}</label>
                                                </div>
                                                <div class="p-px-2 p-mt-2" *ngIf="i != 0 && questionsList[i]?.id != 5">
                                                    <textarea placeholder="Please state the reason" id="reason" rows="2"
                                                        pInputTextarea formControlName="reason"></textarea>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="submit p-mt-2">
                                    <button [class.slide-fade]="true" [class.disabled]="feedbackForm.invalid" class="btnBg" type="submit" [disabled]="feedbackForm.invalid">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div *ngIf="showRejectScreen" id="feedback">
                    <div class="card">
                        <div class="approval-card-header">
                            <img src="../../../../assets/images/aerodyne-email-logo.png" class="logo-chart-screen"
                                alt="Aerodyne">
                        </div>
                        <div class="approval-card-body">
                            <h4 class="p-mb-1">Hi {{customerDetails?.customerName}},</h4>
                            <p>Thank you for your response.</p>
                            <p>Please state the reason for the resolution rejection for ticket
                                <strong>{{customerDetails?.ticketId}}</strong>.
                            </p>
                            <form [formGroup]="updateTimeForm" (ngSubmit)="onRejectSubmit()">
                                <!-- <label class="userLabel required-field" for='comments'>Please state the reason for the
                                    ticket status resolution.</label> -->
                                <div class="p-field">
                                    <textarea style="width: 100%;" id='comments' pInputTextarea [rows]="4"
                                        [maxlength]="maxChars" #comments formControlName="comments"></textarea>
                                    <!-- <span style="float: right; font-size: smaller;" class="pull-right char"><em>{{ 300 -
                                            updateTimeForm?.value?.comments?.length }} characters
                                            left</em></span>
                                    <br> -->
                                    <!-- <input name="file" #resolveFile type="file" (change)="onFileChange($event)" /> -->
                                </div>
                                <button pButton type="submit" label="Submit" class="btnBg"
                                    [disabled]="updateTimeForm.invalid"></button>
                            </form>
                        </div>
                    </div>
                </div>
                <div *ngIf="showThankYouScreen" id="feedback">
                    <div class="card">
                        <div class="approval-card-header">
                            <img src="../../../../assets/images/aerodyne-email-logo.png" class="logo-chart-screen"
                                alt="Aerodyne">
                        </div>
                        <div class="approval-card-body">
                            <h4 class="p-mb-1">Hi {{customerDetails?.customerName}},</h4>
                            <p>Thank you for submitting the user feedback form.</p>
                            <p>Should there be any further questions, please contact us at <a
                                    href="mailto:helpdesk@aerodyne.group"><i>helpdesk{{'@'}}aerodyne.group</i></a>.
                            </p>
                            <p class="p-text-lg-center p-mt-2">Thank you.</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="approvalLevel != ''">
                <div id="feedback" *ngIf="!showThankYouScreen">
                    <div class="card">
                        <div class="approval-card-header">
                            <img src="../../../../assets/images/aerodyne-email-logo.png" class="logo-chart-screen"
                                alt="Aerodyne">
                        </div>
                        <div *ngIf="!changeManagementApproved">
                            <div class="approval-card-body">
                                <h4 class="p-mb-3">Hi Team,</h4>
                                <p class="p-my-2"><strong>Ticket ID : </strong> {{customerDetails?.ticketId}}</p>
                                <p class="p-my-2" *ngIf="customerDetails?.ticketSubject"><strong>Ticket Subject : </strong> {{customerDetails?.ticketSubject}}</p>
                                    <table class="table">
                                        <tbody>
                                            <tr *ngIf="usersApproved">
                                                <td>Ticket Approved By</td>
                                                <td class="colon_section">:</td>
                                                <td>{{usersApproved}}</td>
                                            </tr>
                                            <tr *ngIf="ApprovedUsersCount >= 1">
                                                <td style="width:35%;">Ticket Approval Count &nbsp;</td>
                                                <td class="colon_section">:</td>
                                                <td>{{ApprovedUsersCount}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                <p class="description p-mb-4 p-mt-0">
                                    Please confirm your action.
                                </p>
                            </div>
                            <form [formGroup]="changeManagementApprovalForm"
                                (ngSubmit)="ChangeManagementApprove(submitAction)">
                                <div class="p-field">
                                    <textarea style="width: 100%;" id='comments' pInputTextarea [rows]="4"
                                        [maxlength]="maxChars" #comments formControlName="comments"
                                        placeholder="Please enter your comments"></textarea>
                                </div>
                                <div class="approval-card-footer">
                                    <div class="btnGrid">
                                        <button pButton type="submit" class="btnBg ui-button-raised ui-button-success"
                                            label="Approve" (click)="submitAction = 'approve'"
                                            [disabled]="changeManagementApprovalForm.invalid || isApprovalSubmit"></button>
                                        <button pButton type="submit" label="Reject"
                                            class="ui-button-raised ui-button-danger btnDanger"
                                            (click)="submitAction = 'reject'"
                                            [disabled]="changeManagementApprovalForm.invalid || isApprovalSubmit"></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="changeManagementApproved">
                            <h4 class="p-mb-1">Hi Team,</h4> 
                            <p class="description p-mb-2">
                                Ticket already approved - <strong>{{customerDetails?.ticketId}}</strong>
                            </p>
                            <table class="table">
                                <tbody>
                                    <tr *ngIf="usersApproved">
                                        <td>Ticket Approved By</td>
                                        <td class="colon_section">:</td>
                                        <td>{{usersApproved}}</td>
                                    </tr>
                                    <tr *ngIf="ApprovedUsersCount >= 1">
                                        <td style="width:35%;">Ticket Approval Count &nbsp;</td>
                                        <td class="colon_section">:</td>
                                        <td>{{ApprovedUsersCount}}</td>
                                    </tr>
                                </tbody>
                            </table>
 
                            <!--                            
                            <p class="p-my-2" *ngIf="usersApproved">Ticket Approved By : {{usersApproved}}</p>
                            <p class="p-my-2" *ngIf="ApprovedUsersCount >= 1">Ticket Approval Count : &nbsp;{{ApprovedUsersCount}} / {{totalUsersCount}}</p> -->
                            <p class="description p-mb-3 p-text-lg-center">
                                Should there be any further inquiries, please contact us at
                                <a href="mailto:helpdesk@aerodyne.group">helpdesk{{'@'}}aerodyne.group</a>
                            </p>
                        </div>

                    </div>
                </div>
                <div *ngIf="showThankYouScreen" id="feedback">
                    <div class="card">
                        <div class="approval-card-header">
                            <img src="../../../../assets/images/aerodyne-email-logo.png" class="logo-chart-screen"
                                alt="Aerodyne">
                        </div>
                        <div class="approval-card-body">
                            <h4 class="p-mb-1">Hi Team,</h4>
                            <p>Thank you for your response.</p>
                            <p>Should there be any further questions, please contact us at <a
                                    href="mailto:helpdesk@aerodyne.group"><i>helpdesk{{'@'}}aerodyne.group</i></a>.
                            </p>
                            <p class="p-text-lg-center p-mt-2">Thank you.</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<div class="" style="position: absolute; z-index: 1; top: 50%; left: 50%;" *ngIf="isApprovalSubmit">
    <img class="spinnerLogo" [src]="aeroLogo" alt="aero logo">
</div>