import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

// Services
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.css'],
})
export class ProgressSpinnerComponent implements OnInit {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  aeroLogo = '../../../../assets/images/aerodyne-logo.png';

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {}
}
