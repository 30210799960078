<p-card>
    <h3 class="p-m-1">Settings <i class="fa fa-cog"></i></h3>
</p-card>
<br>
<p-card>
    <div class="ui-fluid file-limit">
        <form [formGroup]="deleteTicketsForm" (ngSubmit)="deleteTicketsById()">
            <h4 class="p-mb-1">Delete Tickets</h4>
            <div class="ui-fluid p-grid p-ai-center">
                <div class="p-col-4 p-md-4">
                    <label class="p-p-0 p-m-0 userLabel required-field" for="file-limit">Enter Ticket ID's</label>
                    <textarea name="" id="" rows="2" formControlName="ticketIds" pInputTextarea></textarea>
                </div>
                <div class="p-col-4 p-md-1 p-ai-end">
                    <br>
                    <button pButton type="submit" label="Delete"></button>
                </div>
            </div>
        </form>
        <form [formGroup]="settingsForm" (ngSubmit)="fileUploadLimit()">
            <h4 class="p-mb-1">Settings</h4>
            <div class="ui-fluid p-grid p-ai-center">
                <div class="p-col-4 p-md-2">
                    <label class="p-p-0 p-m-0 userLabel required-field" for="file-limit">Configuration Name</label>
                    <input type="Text" name="" id="" pInputText formControlName="name">
                </div>
                <div class="p-col-4 p-md-2">
                    <label class="p-p-0 p-m-0 userLabel required-field" for="file-limit">Configuration Value</label>
                    <input type="number" name="" id="" pInputText formControlName="value">
                </div>
                <div class="p-col-4 p-md-1 p-ai-end">
                    <br>
                    <button pButton type="submit" label="Save"></button>
                </div>
            </div>
        </form>
    </div>

    <div class="p-mt-3">
        <!-- <p-table [value]="settingList" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
                <tr>
                    <td>ID</td>
                    <td>Config Name</td>
                    <td>Config Value</td>
                    <td>Action</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td>{{data?.id}}</td>
                    <td>{{data?.name}}</td>
                    <td>{{data?.value}}</td>
                    <td></td>
                </tr>
            </ng-template>
        </p-table> -->
    </div>

    <p-table [value]="settingList" styleClass="p-datatable-gridlines" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr>
                <th>ID</th>
                <th>Configuration Name</th>
                <th>Configuration Value</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td class="p-text-sm-center">{{data?.id}}</td>
                <td>{{data?.name}}</td>
                <td class="p-text-sm-center">{{data?.value}}</td>
                <td class="p-text-sm-center">
                    <div (click)="selectedRow(data)">
                        <i class="fas fa-edit" style="cursor: pointer"></i>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>


<message-modal (closeButton)="closeMessageModal()" #messageModal></message-modal>