import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { SidebarNavigationComponent } from './sidebar-navigation/sidebar-navigation.component';
import { FooterComponent } from './footer/footer.component';
import { DataTableComponent } from './data-table/data-table.component';
import { PrimengModule } from '../primeng/primeng.module';
import { VerticalTimelineComponent } from './vertical-timeline/vertical-timeline.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { DialogComponent } from './dialog/dialog.component';
import { PanelComponent } from './panel/panel.component';
import { EditorComponent } from './editor/editor.component';
import { PaymentComponent } from './payment/payment.component';
import { ViewPaymentComponent } from './view-payment/view-payment.component';
import { ModalComponent } from './modal/modal.component';
import { ModalViewTimelineComponent } from './modal-view-timeline/modal-view-timeline.component';
import { ReportComponent } from './report/report.component';
import { VendorLayoutComponent } from './vendor-layout/vendor-layout.component';
import { ScrollTabComponent } from './scroll-tab/scroll-tab.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { UsersFormComponent } from '../users/users-form/users-form.component';
import { UserProfileViewComponent } from './user-profile-view/user-profile-view.component';
import { IntakeFormComponent } from './forms/intake-form/intake-form.component';
import { LeadProfileComponent } from './lead-profile/lead-profile.component';
// import { UsersModule } from '../users/users.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AccordionModule } from 'primeng/accordion';
import { AssetsComponent } from './assets/assets.component';
import { TasksComponent } from './tasks/tasks.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarComponent } from './calendar/calendar.component';
import { ViewTicketComponent } from './view-ticket/view-ticket.component';
import { IncidentModule } from "../incidents/incidents.module";
import { SlaMatrixModule } from '../sla-matrix/sla-matrix.module';
import { DocumentUploadDirective } from '../../directives/document-upload.directive';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { AssetManagementTableComponent } from './asset-management-table/asset-management-table.component'



@NgModule({
  declarations: [
    HeaderComponent,
    SidebarNavigationComponent,
    FooterComponent,
    DataTableComponent,
    VerticalTimelineComponent,
    DateRangeComponent,
    ProgressSpinnerComponent,
    DialogComponent,
    PanelComponent,
    EditorComponent,
    PaymentComponent,
    ViewPaymentComponent,
    ModalComponent,
    ModalViewTimelineComponent,
    ReportComponent,
    VendorLayoutComponent,
    ScrollTabComponent,
    ConfirmationDialogComponent,
    UserProfileViewComponent,
    IntakeFormComponent,
    LeadProfileComponent,
    AssetsComponent,
    TasksComponent,
    CalendarComponent,
    DocumentUploadDirective,
    DocumentUploadComponent,
    AssetManagementTableComponent
  ],
  exports: [
    HeaderComponent,
    SidebarNavigationComponent,
    FooterComponent,
    DataTableComponent,
    VerticalTimelineComponent,
    DateRangeComponent,
    ProgressSpinnerComponent,
    DialogComponent,
    PanelComponent,
    EditorComponent,
    PaymentComponent,
    ViewPaymentComponent,
    ModalComponent,
    TasksComponent,
    ModalViewTimelineComponent,
    ReportComponent,
    VendorLayoutComponent,
    ScrollTabComponent,
    ConfirmationDialogComponent,
    IntakeFormComponent,
    LeadProfileComponent,
    NgxMaterialTimepickerModule,
    AccordionModule,
    AssetsComponent,
    CalendarComponent,
    DocumentUploadDirective,
    DocumentUploadComponent,
    AssetManagementTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    NgxMaterialTimepickerModule,
    AccordionModule,
    AutoCompleteModule,
  ]
})
export class SharedModule { }
