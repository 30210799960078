<p-dialog [visible]="modalDialog['visible']" modal="true" resizable="false" draggable="false" closeOnEscape="false"
    [showHeader]="false" [closable]="false" styleClass="messageDialog">
    <div class="p-grid" style="padding: 5%;">
        <div *ngIf="modalDialog['imgSrc']" class="p-col-12" style="height: 15vh; text-align: center;">
            <img class="message-image" [src]="modalDialog['imgSrc']" alt="Success"
                style="max-height: 100%; max-width: 100%;">
        </div>
        <div class="p-col-12" style="text-align: center;">
            <h4 class="bold" [innerHTML]="modalDialog.message || ''"></h4>
        </div>
        <div class="p-col-12 action-btn">
            <button pButton type="button" [label]="modalDialog?.rejectLabel" (click)="closeModal();closeButtonClick();"
                class="modal-btn ui-button-danger"></button>
            <button pButton type="button" class="btnBg modal-btn" [label]="modalDialog?.confirmLabel"
                (click)="_confirm()"></button>
        </div>
    </div>


</p-dialog>