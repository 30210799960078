import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class JwtService {

    private refreshUrl = `${environment.api_url}refresh-token`;

    constructor(private http: HttpClient) {}
    
    // get token by decrypt
    getToken(): string {
        let _token = localStorage.getItem('Token');
        _token = (_token) ? atob(_token) : '';
        return _token;
    }

    // save token by encrypt
    saveToken(token: string) {
        let _token = btoa(token);
        localStorage.setItem('Token', _token)
    }

    // destroy token
    destroyToken() {
        localStorage.removeItem('Token');
    }

    getNewToken(): Observable<any> {
        const refreshToken = this.getToken();
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + refreshToken);
        return this.http.get<any>(this.refreshUrl, { headers });

    }
    
}
