import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ModalComponent } from '../shared/modal/modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {


  settingsForm: FormGroup;
  deleteTicketsForm: FormGroup;
  settingList: any[] = [];
  @ViewChild('messageModal') messageModal: ModalComponent;

  constructor(private fb: FormBuilder, private apiService: ApiService) {
    this.settingsForm = this.fb.group({
      name: ['', [Validators.required]],
      value: ['', [Validators.required]],
    });
    this.deleteTicketsForm = this.fb.group({
      ticketIds: [[], [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getSettings();
  }
  onResetForm() {
    this.settingsForm.reset({
      name: '',
      value: '',
    });
  }
  selectedRowData: boolean = false;
  selectedRow(data: any) {
    this.settingsForm.patchValue(data);
    this.selectedRowData = true;
  }
  getSettings() {
    this.apiService.get('get-settings').subscribe(
      (result) => {
        this.settingList = result?.data.sort((a, b) => a.id - b.id);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fileUploadLimit() {
    let payload = {
      name: this.settingsForm.value?.name,
      value: this.settingsForm.value?.value
    }
    this.apiService.post('add-update-settings', payload).subscribe((result) => {
      this.getSettings();
      this.settingsForm.reset();
    });
  }
  deleteTicketsById() {
    let ticketIdsString = this.deleteTicketsForm.value.ticketIds;
    let payload = {
      ticketIds: ticketIdsString.split(',').map(id => id.trim())
    }
    this.apiService.post('soft-delete', payload).subscribe((result) => {
      this.messageModal.successDialog(
        'Deleted Successfully ' + `</br>`
        + payload.ticketIds
      );
      this.deleteTicketsForm.reset();
    });
  }
  closeMessageModal() { }
}
