
import { Directive, EventEmitter, HostListener, Output, Input } from '@angular/core';
import { documentUploadServices } from '../services/document-upload.service';

export interface DocumentUploadData {
  status: boolean
  message: string
  files: Files
}

export interface Files {
  filename: string
  mimeType: string
  size: number
  status: boolean
  url: string
  createdAt: string
  updatedAt: string
  id: string
}

@Directive({
  selector: '[documentUpload]',
})
export class DocumentUploadDirective {
  @Output() getDocument: EventEmitter<DocumentUploadData> = new EventEmitter();
  @Input() moduleName: string = ''
  constructor(private documentUploadServices: documentUploadServices) { }

  @HostListener('change', ['$event'])
  public onChange(event: Event): void {

    event.preventDefault();
    event.stopPropagation();

    const file = (event.target as HTMLInputElement).files[0] as File;

    const formData = new FormData();
    formData.append('file', file);

    this.documentUploadServices.onFileUpload(formData,this.moduleName).subscribe((fileData: DocumentUploadData) => {
      this.getDocument.emit(fileData)
    });

  }

}
