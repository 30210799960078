<div class="sidebar-navigation basic-bg-2 basic-bg-2-border">
  <div class="profile">
    <div class="profile-picture">
      <!-- <img src="../../../../assets/images/photo.png" alt="profile"> -->
      <img [src]="image_url" alt="profile" (error)="handleImageError($event)">
    </div>
    <h5 class="margin-0">{{_session['fullname'] || ''}}</h5>
    <p class="role margin-0 goldenrod-color">{{_session['designationName'] || ''}} -
      {{usersCompany ? usersCompany : ''}}
    </p>
    <a (click)="getUserDetails()" class="view-profile"> <i class="fas fa-eye"></i> View Profile</a>
  </div>
  <div class="sidebar-floatMenu">
    <div id="SideNav" class="sidenav">
      <a *ngIf="showTicket" id="dashboardMenu"
        (click)="onselectMenu('dashboardMenu', 'dashboardsubMenu'); navigate('dashboard');onselectTicket(0)"><i
          class="fas fa-tachometer-alt"></i> &nbsp;Ticket Queue
        <i class="fa fa-caret-down"></i></a>
      <div id="dashboardsubMenu" class="dropdown-container">
        <a *ngFor="let type of ticketType" [class.active]="type.id === selectedType"
          (click)="onselectTicket(type.id); navigate('dashboard')">{{type?.name}}</a>
        <a (click)="onselectMenu('dashboardMenu', 'dashboardsubMenu'); navigate('dashboard/ticket-drafts')">Draft</a>
      </div>
      <!-- <a id="notificationMenu" (click)="onselectMenu('notificationMenu'); navigate('notification')"><i class="fa fa-bell"></i> &nbsp;Notifications</a> -->
      <!-- <a *ngIf="showDashboard" id="chartsMenu" (click)="onselectMenu('chartsMenu'); navigate('charts')"><i
          class="fas fa-chart-pie"></i> &nbsp;Dashboard</a> -->
      <!-- <a id="appointmentMenu" (click)="onselectMenu('appointmentMenu'); navigate('appointment')"><i
          class="fas fa-calendar-check"></i> &nbsp;Appointment</a>
      <a id="calendarMenu" (click)="onselectMenu('tasksMenu'); openCalendar()"><i class="far fa-calendar"></i>
        &nbsp;Calendar</a> -->
      <a *ngIf="showHealthSnapshot && permissionsWithDescriptions" id="healthSnapshot" class="tooltip"
        (click)="onselectMenu('healthSnapshot'); navigate('health-snapshot')">
        <i class="fas fa-chart-pie"></i> &nbsp;Health Snapshot
        <span class="tooltiptext">{{ getDescription('healthSnapshot') }}</span>
      </a>
      <a *ngIf="showKnowledgeBase && permissionsWithDescriptions" id="knowledgeBase" class="tooltip"
        (click)="onselectMenu('knowledgeBase'); navigate('knowledge-base')">
        <i class="fas fa-server"></i> &nbsp;Knowledge Base
        <span class="tooltiptext">{{ getDescription('knowledgebase') }}</span>
      </a>
      <a *ngIf="showAssetManagement && permissionsWithDescriptions" id="assetsManagement" class="tooltip"
        (click)="onselectMenu('assetsManagement'); navigate('asset-management')">
        <i class="fa fa-folder"></i> &nbsp;Asset Management
        <span class="tooltiptext">{{ getDescription('management') }}</span>
      </a>
      <a id="feedbacks" *ngIf="showFeedback && permissionsWithDescriptions" class="tooltip"
        (click)="onselectMenu('feedbacks'); navigate('feedback')">
        <i class="fa fa-comment"></i> &nbsp;Feedback
        <span class="tooltiptext">{{ getDescription('feedback') }}</span>
      </a>
      <!-- <a id="formsMenu" (click)="onselectMenu('formsMenu'); navigate('forms')"><i class="fa fa-window-maximize"></i> &nbsp;Forms</a> -->
      <!-- <a *ngIf="showTask" id="tasksMenu" (click)="onselectMenu('tasksMenu'); openTasks()"><i class="fas fa-tasks"></i>
        &nbsp;Tasks</a> -->
      <a *ngIf="showConfiguration" id="managementMenu" class="dropdown-btn"
        (click)="onselectMenu( 'managementMenu' , 'managementSubMenu')"><i class="fas fa-cogs"></i> &nbsp;Configuration
        <i class="fa fa-caret-down"></i></a>
      <div id="managementSubMenu" class="dropdown-container">
        <a id="activityLog" class="tooltip" (click)="onselectMenu('activityLog'); navigate('activity-log')"
          *ngIf="showActivityLog && permissionsWithDescriptions">
          Activity Log
          <span class="tooltiptext">{{ getDescription('showActivityLog') }}</span>
        </a>
        <a *ngIf="showAnnouncement && permissionsWithDescriptions" id="announcements" class="tooltip"
          (click)="onselectMenu('announcements'); navigate('announcements')">Announcements
          <span class="tooltiptext">{{ getDescription('announcement') }}</span>
        </a>
        <!-- <a *ngIf="showAssetType" id="Assettypes" (click)="onselectMenu('Assettypes'); navigate('asset-type')">Asset Types</a> -->
        <!-- <a *ngIf="showAsset" id="assets" (click)="onselectMenu('assets');openAssets();">Assets</a> -->
        <a *ngIf="showAssignmentSubGrp && permissionsWithDescriptions" id="applicationMenu" class="tooltip"
          (click)="onselectMenu('applicationMenu'); navigate('service-type')">Assignment Group
          <span class="tooltiptext">{{ getDescription('serviceType') }}</span>
        </a>
        <a *ngIf="showAssetMaster && permissionsWithDescriptions" id="assetsManagementMaster" class="tooltip"
          (click)="onselectMenu('assetsManagementMaster'); navigate('asset-management-master')">Asset Master
          <span class="tooltiptext">{{ getDescription('assetMaster') }}</span>
        </a>
        <a *ngIf="showOrganisation && permissionsWithDescriptions" id="organisationMenu" class="tooltip"
          (click)="onselectMenu('organisationMenu'); navigate('organisation')">Companies
          <span class="tooltiptext">{{ getDescription('organization') }}</span>
        </a>
        <a *ngIf="showAssignmentGrp && permissionsWithDescriptions" id="supportGroup" class="tooltip"
          (click)="onselectMenu('supportGroup'); navigate('support-group')">Department
          <span class="tooltiptext">{{ getDescription('supportGroup') }}</span>
        </a>
        <a *ngIf="showFaq && permissionsWithDescriptions" id="faq" class="tooltip"
          (click)="onselectMenu('faq'); navigate('frequently-asked')">FAQ's
          <span class="tooltiptext">{{ getDescription('faq') }}</span>
        </a>
        <a *ngIf="showUser && permissionsWithDescriptions" id="usersMenu" class="tooltip"
          (click)="onselectMenu('usersMenu'); navigate('user')">Licensed Users
          <span class="tooltiptext">{{ getDescription('user') }}</span>
        </a>
        <!-- <a *ngIf="showDepartment" id="department" (click)="onselectMenu('department'); navigate('department')">Group</a> -->
        <!-- <a *ngIf="showIncident" id="incidentsMenu" (click)="onselectMenu('incidentsMenu'); navigate('incident')">Incidents</a> -->
        <!-- <a *ngIf="showProblem" id="problemsMenu" (click)="onselectMenu('problemsMenu'); navigate('problems')">Problems</a> -->
        <a *ngIf="showReport && permissionsWithDescriptions" id="reportsMenu" class="tooltip"
          (click)="onselectMenu('reportsMenu'); navigate('report')">Reports
          <span class="tooltiptext">{{ getDescription('reports') }}</span>
        </a>
        <a *ngIf="showContactInfo && permissionsWithDescriptions" id="contactInfo" class="tooltip"
          (click)="onselectMenu('contactInfo'); navigate('contact-info')">Requestor
          <span class="tooltiptext">{{ getDescription('contactInfo') }}</span>
        </a>
        <a *ngIf="showRoles && permissionsWithDescriptions" id="roles" class="tooltip"
          (click)="onselectMenu('roles'); navigate('role')">Roles
          <span class="tooltiptext">{{ getDescription('role') }}</span>
        </a>
        <!-- <a id="standard-master" class="tooltip"
          (click)="onselectMenu('standard-master'); navigate('standard-master')">Standard Master List
          <span *ngIf="getDescription('standard-master')" class="tooltiptext">{{ getDescription('standard-master')
            }}</span>
        </a>
        <a id="schedule" class="tooltip" (click)="onselectMenu('schedule'); navigate('schedule')">Schedule
          <span *ngIf="getDescription('schedule')" class="tooltiptext">{{ getDescription('schedule') }}</span>
        </a> -->
        <a *ngIf="showslaEvent" id="slaConfigMenu" class="tooltip"
          (click)="onselectMenu('slaConfigMenu'); navigate('sla-config')">SLA Events
          <span class="tooltiptext">{{ getDescription('extendSla') }}</span>
        </a>
        <a *ngIf="showSlaModel && permissionsWithDescriptions" id="slaMatrixMenu" class="tooltip"
          (click)="onselectMenu('slaMatrixMenu'); navigate('sla')">SLA Model
          <span class="tooltiptext">{{ getDescription('slaModel') }}</span>
        </a>
        <a *ngIf="showSupportLevel && permissionsWithDescriptions" id="supportLevel" class="tooltip"
          (click)="onselectMenu('supportLevel'); navigate('support-level')">Support Level
          <span class="tooltiptext">{{ getDescription('supportLevel') }}</span>
        </a>
        <a *ngIf="showTicketRecords && permissionsWithDescriptions" id="ticketRecords" class="tooltip"
          (click)="onselectMenu('ticketRecords'); navigate('ticket-records')">Ticket Records
          <!-- <span class="tooltiptext">{{ getDescription('settings') }}</span> -->
        </a>
        <a *ngIf="showSettings && permissionsWithDescriptions" id="settings" class="tooltip"
          (click)="onselectMenu('settings'); navigate('settings')">Settings <i class="fa fa-cog"></i>
          <!-- <span class="tooltiptext">{{ getDescription('settings') }}</span> -->
        </a>
      </div>
      <!-- <a (click)="_logout()"><i class="fas fa-sign-out-alt"></i>  &nbsp;Logout</a> -->
      <!-- Asset Management -->
      <!-- <a *ngIf="userConfig && userConfig['management']" id="assetManagementMenu" class="dropdown-btn"
        (click)="onselectMenu('assetManagementMenu', 'AssetManagementSubMenu')">
        <i class="fa fa-folder" aria-hidden="true"></i> &nbsp;Asste Management
        <i class="fa fa-caret-down"></i>
      </a>
      <div id="AssetManagementSubMenu" class="dropdown-container">
        <a id="asset" (click)="onselectMenu('asset'); navigate('asset-management/asset')">Asset</a>
        <a id="license" (click)="onselectMenu('license'); navigate('asset-management/license')">
          License
        </a>
      </div> -->
    </div>
    <div class="float-menu-container">
      <div class="floatMenu" [class.visible]="isFloatMenuVisible">
        <a class="ticket-status">Ticket status</a>
        <a class="ticket-statuss"> (Last 30 Days) </a>
        <ul class="ticket-status-list">
          <li *ngFor="let status of ticketStatusCount">
            <span class="ticket-status-label">{{status.label}}</span>
            <span class="ticketstatusCls" [ngStyle]="{'backgroundColor': status.colorCode}">{{status.value ||
              '0'}}</span>
          </li>
        </ul>
      </div>
      <div class="floatingBtn" (click)="toggleFloatMenu()">

        <ul class="ticket-status-list ticket-card showHide">

          <div class="ticket-button">
            <li *ngFor="let status of ticketStatusCount">
              <!-- <span class="ticket-status-label">{{status.label}}</span> -->
              <span class="ticketstatusCls" [ngStyle]="{'backgroundColor': status.colorCode}">{{status.value ||
                '0'}}</span>
            </li>
            <li style="width: 50%;" class="arrow" [ngClass]="{'rotated': isArrowRotated}"></li>
          </div>
        </ul>

        <div class="ticketCount">
          <span class="ticketBtn" *ngFor="let status of ticketStatusCount">
            <span class="ticketstatusCls" [ngStyle]="{'backgroundColor': status.colorCode}">
              {{status.value || '0'}}
            </span>
          </span>
          <span style="width: 50%;" class="arrow" [ngClass]="{'rotated': isArrowRotated}"></span>
        </div>

      </div>
    </div>
  </div>
</div>

<common-dialog [dialogConfig]="viewDialog" (closeModal)="closeViewProfile($event)">

  <user-profile-view *ngIf="viewProfileData" [formData]="viewProfileData"
    (profileImgChanged)="ngOnInit(); getUserDetails()"></user-profile-view>
</common-dialog>

<common-dialog [dialogConfig]="assetsDialogue" (closeModal)="closeViewProfile($event)">
  <assets *ngIf="assetViewed"></assets>
</common-dialog>

<common-dialog [dialogConfig]="taskDialog" class="task-modal" (closeModal)="closeViewProfile($event)">
  <app-tasks *ngIf="taskViewed"></app-tasks>
</common-dialog>

<!-- Calendar Iframe fetched from calendar component -->
<common-dialog [dialogConfig]="calendarModalDialogue" (closeModal)="closeViewProfile($event)">
  <app-calendar></app-calendar>
</common-dialog>