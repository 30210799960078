import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Dropdown, LazyLoadEvent } from 'primeng';
import { Subscription } from 'rxjs';
import { IDataTable } from 'src/app/models/modules/shared/data-table.model';
import { IPanel } from 'src/app/models/modules/shared/panel.model';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { socketService } from 'src/app/services/socket.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-ticket-records',
  templateUrl: './ticket-records.component.html',
  styleUrls: ['./ticket-records.component.css']
})
export class TicketRecordsComponent implements OnInit {
  closeDialog: any;
  dataTable: IDataTable = {
    columns: [
      { field: 'ticketId', header: 'Ticket ID'},
      { field: 'requestTypeName', header: 'Request Type' },
      { field: 'subject', header: 'Subject' },
      { field: 'priority', header: 'Priority' },
      { field: 'urgency',header: 'Urgency' },
      { field: 'ticketType', header: 'Ticket Type' },
      { field: 'ticketCategoryName', header: 'Ticket Category' },
      { field: 'assetName', header: 'Configuration Item' },
      { field: 'ticketStatus', header: 'Ticket Status' },
      { field: 'organizationName', header: 'Company Name' },
      { field: 'departmentName', header: 'Department' },
      { field: 'customerName', header: 'Requestor Full Name' },
      { field: 'customerEmail', header: 'Requestor Email' },
      { field: 'serviceTypeName', header: 'Assignment Group' },
      { field: 'assignedToName', header: 'Ticket Assignee' },
      { field: 'ticketResponseDescription', header: 'Ticket Response Description' },
      { field: 'createdOn',header: 'Created On' },
      { field: 'actualResponseTime',header: 'Actual Response Time (Min)' },
      { field: 'respondedOn',header: 'Responded On' },
      { field: 'actualRestorationTime',header: 'Actual Resolution Time (Min)' },
      { field: 'resolvedOn',header: 'Resolved On' },
      { field: 'plannedResponseTime',header: 'Planned Response Time (Min)' },
      { field: 'plannedResolutionTime',header: 'Planned Resolution Time (Min)' },
    ],
    data: [],
    scrollHeight: '20',
    rows: 50,
    totalRecords: 50,
    addButton: false,
    editButton: false,
    viewButton: true,
    csvExcelPdfButton: true,
    searchOptionOnly: false,
    exportFileName:'Ticket Records',
    pdfLayoutChange:true,
    lazyLoad:true
  };

  @Output() openTicketDetails = new EventEmitter();
  @ViewChild('agentDropdown') agentDropdown: Dropdown;

  ticketList = [];
  first = 0;
  // rows = 50;
  totalRecords = 0;
  loading: boolean;
  counts: any;
  ticketCount = 0;
  activeTab = 0;
  overDueStatus: boolean;
  ticketSearchCall: any = '';
  sortField: any;
  sortOrder: any;
  ticketStatusSocket$: Subscription;
  getTicketServiceList$: Subscription;
  getSortTypeList$: Subscription;
  getTicketStatusList$: Subscription;
  getAllUserList$: Subscription;
  getFilterTicket$: Subscription;
  from_date = null;
  to_date = null;
  rangeDates = null;
  // selectedMyTicketFilter = true;
  statusFilterList = [];
  serviceFilterList = [];
  userList = [];
  panelColapsed = false;
  supportGroup: any[];
  selectedStatusFilter = null;
  selectedServiceFilter = null;
  selectedExtraFilter = null;
  selectedAgentFilter = null;
  selectedPriorityFilter = null;
  selectedCompanyFilter= null;
  selectedRequestTypeFilter= null;
  viewPanel2: IPanel = {
    showHeader: true,
    toggleable: true,
    expandIcon: 'pi pi-angle-up',
    collapseIcon: 'pi pi-angle-down',
    headerColor: '#337AB7',
  };
  chooseTimelineFilter = [
    { name: 'Today', label: 'Today' },
    { name: 'This week', label: 'This_week' },
    { name: 'This month', label: 'This_month' },
    { name: 'This year', label: 'This_year' },
    { name: 'Custom date', label: 'Custom_date' },
  ].sort((a, b) => a.name.localeCompare(b.name));
  // ticketType = [
  //   { name: 'All Tickets', label: 'All_Tickets' },
  //   { name: 'My Tickets', label: 'My_Tickets' },
  // ].sort((a, b) => a.name.localeCompare(b.name));
  selectedDayFilter: any;
  selectedTicketType: any = { name: '', label: '' };
  showCalendar: boolean = false;
  appliedFilterQuery = '';
  isOverDue: boolean = false;

  newTicket: Boolean = true;
  inprogressTicket: Boolean = false;
  resolvedTicket: Boolean = false;
  closedTicket: Boolean = false;
  onHoldTicket: Boolean = false;
  cancelledTicket: Boolean = false;
  showFilterBy: boolean = false;
  serviceFilterListSession: any;
  serviceTypeList: any;
  showUser: boolean = true;
  // tabs = ['new', 'inprogress', 'onhold', 'cancelled', 'resolved', 'closed'];
  Priority = [];
  spinner: boolean = false;
  aeroLogo = '../../../assets/images/aerodyne-logo.png';
  totalRecordCount: number;
  companiesList:any[]=[];
  requestTypes:any[]=[];
  searchText = '';
  constructor(
    private apiService: ApiService,
    private socketService: socketService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // this.getTicketDetails()
    this.getAllUserList();
    this.filterByDate(this.selectedDayFilter?.label);
    this.getFilterTicket();

    this.getTicketStatusList();
    this.getTicketServiceList();
    this.getPriority();
    this.getOrganizationsList();
    this.getRequestTypes();
    // this.dataService.selectedSupportGroups$.subscribe((groups) => {
    //   this.supportGroup = groups;
    //   if (this.supportGroup && this.supportGroup?.length) {
    //     this.getServiceTypes();
    //   }
    // });
  }
  getPriority() {
    this.apiService.get('ticket-sla/get-all').subscribe((response) => {
      let responseData = response.data?.map((item) => ({
        name: item?.priority,
        value: item?.id,
        day: item?.restoreSlaDays,
      }));
      this.Priority = responseData.sort((a, b) => a.name.localeCompare(b.name));
    });
  }
  passDataToForm(value: any) {
    let ticketId = value?.data?.id;
    if (ticketId) {
      const url = `/dashboard/ticket-details/view/${ticketId}`;
      const newTab = window.open('', '_blank');
      newTab.location.href = url;
    }
  }
  formatRangeDates(): string {
    if (!this.rangeDates || this.rangeDates.length === 0) {
      return '';
    }

    const startDate = this.datePipe.transform(
      this.rangeDates[0],
      'MMM dd, yyyy'
    );
    const endDate = this.datePipe.transform(
      this.rangeDates[this.rangeDates.length - 1],
      'MMM dd, yyyy'
    );
    return `[ ${startDate} - ${endDate} ]`;
  }

  handleDayFilterChange() {
    // If 'Custom_date' is selected, show the calendar
    this.filterByDate(this.selectedDayFilter.label);
    const filterButtons = document.getElementById('filterButtons');
    if (this.selectedDayFilter.label === 'Custom_date') {
      this.showCalendar = true;
      this.renderer.addClass(filterButtons, 'p-col-2');
      this.renderer.removeClass(filterButtons, 'p-col-4');
    } else {
      this.showCalendar = false;
      this.renderer.addClass(filterButtons, 'p-col-4');
      this.renderer.removeClass(filterButtons, 'p-col-2');
    }
  }

  isNumber(value: any): boolean {
    return !isNaN(value);
  }
  getTicketDetails(query?) {
    this.spinner = true;
    if (this.ticketStatusSocket$) this.ticketStatusSocket$.unsubscribe();
    var search = this.ticketSearchCall
      ? `&search=${this.ticketSearchCall}`
      : '';
    var sort =
      this.sortOrder && this.sortField
        ? `&orderBy=${this.sortField}&sort=${this.sortOrder}`
        : '';
    // var _url = `ticket-filter?skip=${this.first}&limit=${this.rows}${search}${sort}`;
    // if (query == 'myTicket') {
    //   var _url = `ticket-records?skip=${this.first}&limit=${search}${sort}`;
    // } else 
    if (query == null) {
      var _url = `ticket-records?skip=${this.first}&limit=${search}${sort}`;
    } else {
      var _url = `ticket-records?skip=${this.first}&limit=`;
    }
    _url += this.appliedFilterQuery;
    this.apiService.get(_url).subscribe((e) => {
      if (!e.status) return;
      let resData = e.data;
      this.totalRecordCount = e?.data?.length;
       
      this.ticketList = resData.map(ticket => ({
        ...ticket,
        assetName: ticket.asset?.asset_name || null,
        organizationName: ticket.organization?.name || null,
        assignedToName: ticket.assignedTo?.fullname || null,
        serviceTypeName: ticket.service_type?.name || null,
        ticketResponseDescription: ticket?.description || null,
        ticketStatus: ticket?.currentStatus?.status?.status || null,
      }));
      this.dataTable = {
        ...this.dataTable,
        data: this.ticketList,
        totalRecords: e.ticketCount
      }
      this.ticketStatusSocket$ = this.socketService
        .getMessage(`_LISTEN_TICKETSTATUS`)
        .subscribe((msg) => {
          let ticketId = msg.value.ticketId;
          let status = msg.value.currentStatus.status.status;
          let assignedTo = msg.value.assignedTo.id;
          if (query == 'closed' && status != 'Closed') {
            return false;
          }
          let matched = false;
          for (let index = 0; index < this.ticketList.length; index++) {
            const element = this.ticketList[index];
            if (element.ticketId == ticketId) {
              matched = true;
              this.ticketList[index] = msg.value;
            }
          }
          if (matched == false) {
            this.ticketList = [...[msg.value], ...this.ticketList];
            this.ticketList = resData.map(ticket => ({
              ...ticket,
              assetName: ticket.asset?.asset_name || null,
              organizationName: ticket.organization?.name || null,
              assignedToName: ticket.assignedTo?.fullname || null,
              serviceTypeName: ticket.service_type?.name || null,
              ticketResponseDescription: ticket?.description || null,
              ticketStatus: ticket?.currentStatus?.status?.status || null,
            }));
            this.dataTable = {
              ...this.dataTable,
              data: this.ticketList,
              totalRecords: e.ticketCount
            }
            
          }
          // this.updateOverDueCheck();
        });
      this.spinner = false;
      // this.totalRecords =
      //   e?.result[query === 'myTickets' ? 'assigned' : query] || 0;

      this.counts = e.result;
    });
  }

  openTicket(ticket) {
    let ticketId = localStorage.setItem('ticketId', ticket?.ticketId);
    this.openTicketDetails.emit(ticket['id']);
  }

  loadTickets(event: LazyLoadEvent) {
    this.loading = true;

    //in a real application, make a remote request to load data using state metadata from event
    this.first = event.first;
    // this.rows = event.rows;
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    this.sortField = event.sortField;
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    if (event.sortOrder == 1) {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }

    //imitate db connection over a network
    setTimeout(() => {
      this.getTicketDetails(null);

      this.loading = false;
    }, 1000);
  }


  filterByDate(data: any) {
    let date = new Date();
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    let from_date = d.getTime();
    let to_date = new Date().getTime();
    switch (data) {
      case 'Today':
        let e = new Date();
        e.setDate(new Date().getDate() + 1);
        e.setHours(0, 0, 0, 0);
        to_date = e.getTime();
        break;
      case 'This_week':
        let day = date.getDay();
        let firstday = new Date(date.getTime() - 60 * 60 * 24 * day * 1000);
        let lastday = new Date(firstday.getTime() + 60 * 60 * 24 * 6 * 1000);
        from_date = firstday.getTime();
        to_date = lastday.getTime();
        break;
      case 'This_month':
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        from_date = firstDay.getTime();
        to_date = lastDay.getTime();
        break;
      case 'This_year':
        var start = new Date('1/1/' + new Date().getFullYear());
        var end = new Date('12/31/' + new Date().getFullYear());
        from_date = start.getTime();
        to_date = end.getTime();
        break;
      default:
        break;
    }
    this.from_date = from_date;
    this.to_date = to_date;
  }

  rangeSelected() {
    if (this.rangeDates && this.rangeDates.indexOf(null) == -1) {
      let from_date = new Date(this.rangeDates[0]).getTime();
      let to_date = new Date(this.rangeDates[1]).getTime();

      this.from_date = from_date;
      this.to_date = to_date;
    }
  }

  getTicketStatusList() {
    this.getTicketStatusList$ = this.apiService
      .get(`get-ticket-status-master?type=timeline`)
      .subscribe((response) => {
        this.statusFilterList = [...response.data];
        // let ticketList = [{ status: 'All', id: null }, ...response.data];
        // this.statusFilterList = ticketList.filter(
        //   (ticket) => ticket.status !== 'Closed'
        // );
      });
  }

  getTicketServiceList() {
    const _session = this.userService.getSession();

    this.getTicketServiceList$ = this.apiService
      .get(`get-service-type?sort=ASC&orderBy=name`)
      .subscribe((response) => {
        this.serviceTypeList = [
          ...response.data,
        ].sort((a, b) => a.name.localeCompare(b.name));
        this.serviceFilterList = this.serviceTypeList;
      });
      
  }
  
  getServiceTypes() {
    // const selectedServiceTypes = {};
    let _body = {
      supportGroup: this.supportGroup,
    };
    this.apiService.post('get-user-servicetype', _body).subscribe(
      (result) => {
        const serviceTypes = result?.map((service) => {
          return {
            name: service?.support_group?.name + '-' + service?.name,
            value: service?.id,
          };
        });
        this.serviceFilterList = [
          ...serviceTypes,
        ];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAllUserList() {
    this.getAllUserList$ = this.apiService
      .get(`get-user-all`)
      .subscribe((response) => {
        this.userList = [];
        let temp = [];
        response.data.map((user) => {
          temp.push({ name: user.fullname, code: user.id });
        });
        this.userList = [...temp];
      });
  }

  getFilterTicket(skip:number=0,limit:number=50,searchKey='',sortBy='createdOn',sortOrder='DESC') {
    const dataSkip = skip;
    const dataLimit = 50;
    let _url = `ticket-records?skip=${dataSkip}&limit=${dataLimit}&search=${searchKey}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    let filterQuery = '';
    if (this.selectedDayFilter)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}from=${this.from_date
        }&to=${this.to_date}`;
    if (this.selectedStatusFilter) {
      const status = this.selectedStatusFilter?.map(val => val?.id)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}status=${status ? status : ''
        }`;
    }
    if (this.selectedServiceFilter) {
      const serviceType = this.selectedServiceFilter?.map(service => service?.id)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}serviceType=${serviceType ? serviceType : ''
        }`;
    }
    if (this.selectedAgentFilter && this.selectedAgentFilter.code != null)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}agent=${this.selectedAgentFilter ? this.selectedAgentFilter.code : ''
        }`;
    
    if (this.selectedPriorityFilter) {
      const priority = this.selectedPriorityFilter?.map(val => val?.name)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}priority=${priority ? priority : ''}`;
    }    
    if (this.selectedCompanyFilter) {
      const organization = this.selectedCompanyFilter?.map(val => val?.id)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}organization=${organization ? organization : ''}`;
    }     
    if (this.selectedRequestTypeFilter) {
      const requestType = this.selectedRequestTypeFilter?.map(val => val?.id)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}requestType=${requestType ? requestType : ''}`;
    } 
    
    if (this.overDueStatus)
      filterQuery += `${!_url.includes('?') ? '?' : '&'}overdue=true`;

    _url += filterQuery;
    this.appliedFilterQuery = filterQuery;
    this.getFilterTicket$ = this.apiService.get(_url).subscribe((e) => {
      if (!e.status) return;
      this.viewPanel2.collapsed = false;
      // const _data = isLoadMore ? [...this.ticketList, ...e.data] : e.data;
      const _data = e.data;
      let resData = _data;
      this.ticketList = resData.map(ticket => ({
        ...ticket,
        assetName: ticket.asset?.asset_name || null,
        organizationName: ticket.organization?.name || null,
        assignedToName: ticket.assignedTo?.fullname || null,
        serviceTypeName: ticket.service_type?.name || null,
        ticketResponseDescription: ticket?.description || null,
        ticketStatus: ticket?.currentStatus?.status?.status || null,
        requestTypeName: ticket?.requestType?.name || null,
        departmentName: ticket?.support_group?.name || null,
        ticketCategoryName: ticket?.ticketCategory?.name || null,
        createdOn: this.formatDate(ticket.createdAt),
        resolvedOn: this.getResolvedDate(ticket),
        respondedOn: ticket?.respondedDate ? this.formatDate(ticket?.respondedDate,true) : this.formatDate(ticket.createdAt)
      }));

      const priorityMap = {
        P1: { plannedResponseTime: 60, plannedResolutionTime: 1440 },
        P2: { plannedResponseTime: 240, plannedResolutionTime: 2880 },
        P3: { plannedResponseTime: 480, plannedResolutionTime: 4320 },
        P4: { plannedResponseTime: 480, plannedResolutionTime: 7200 }
      };
      this.ticketList.forEach(ticket => {
        const priorityValues = priorityMap[ticket.priority];
        if (priorityValues) {
          ticket['plannedResponseTime'] = priorityValues.plannedResponseTime;
          ticket['plannedResolutionTime'] = priorityValues.plannedResolutionTime;
        }
      });

      this.dataTable = {
        ...this.dataTable,
        data: this.ticketList,
        totalRecords: e.ticketCount
      }
      this.ticketCount = e.ticketCount;

      this.counts = e.result;
      
      this.showFilterBy = true;
    });
  }

  getResolvedDate(ticket: any) {
    let currentStatus = ticket?.currentStatus?.status?.status
    let ticketActivity = ticket.ticketActivities
    if (currentStatus == 'Resolved' || currentStatus == 'Closed') {
      const lastTicketWithStatus = ticketActivity?.filter(ticket => ticket.ticket_status !== null) // Filter tickets with non-null ticket_status
        .reduce((lastTicket, currentTicket) => {
          // Compare createdAt and get the last available element
          return currentTicket.ticket_status.createdAt > (lastTicket?.ticket_status?.createdAt || 0) ? currentTicket : lastTicket;
        }, null);
      const resolvedDate = lastTicketWithStatus ? this.formatDate(lastTicketWithStatus?.ticket_status?.createdAt) : null;

      return resolvedDate
    } else {
      return null
    }

  }
  
  clearFilter() {
    this.selectedStatusFilter = [];
    this.selectedServiceFilter = [];
    this.selectedTicketType = '';
    this.selectedDayFilter = '';
    this.selectedAgentFilter = '';
    this.appliedFilterQuery = '';
    this.overDueStatus = false;
    // this.agentDropdown.clear(null);
    this.activeTab = 0;
    this.isOverDue = false;
    this.showFilterBy = false;
    this.rangeDates = '';
    this.showUser = true;
    this.showCalendar = false;
    this.selectedCompanyFilter= [];
    this.selectedRequestTypeFilter= [];
    this.selectedPriorityFilter= [];
    // this.getTicketDetails('new');
    let date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.from_date ='';
    this.to_date ='';
    this.getFilterTicket();
  }

  getOrganizationsList(): void {
    this.apiService.get('organization/get-all').subscribe((result) => {
      this.companiesList = result?.data?.organizations.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    });
  }

  getRequestTypes() {
    this.dataService.requestTypes$.subscribe((types) => {
      const data = types.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.requestTypes = data;
    });
  }

  formatDate(dateString: string,isDateFormatChange?:boolean): string {
    let timestamp;
    if(isDateFormatChange){
      const date = new Date(dateString);
      const updatedDateString = date.getTime();
      timestamp = Number(updatedDateString);
    }else{
      timestamp = Number(dateString);
    }
    
    const date = new Date(timestamp);
  
    // Format the date components using local time
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();
  
    // Format the time components using local time
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set it to 12 (for 12 AM instead of 00)
  
    const formattedHours = String(hours).padStart(2, '0');
  
    // Return formatted date and time with AM/PM in 12-hour format
    return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
  }

  onPaginationChange(event:any){
    let sortField = event?.sortField ? event?.sortField : 'createdOn'
    let orderType = event?.sortOrder == 1 ? 'ASC' : 'DESC'
    let sortOrder = event?.sortField ? orderType : 'DESC'
    if(sortField == 'plannedResponseTime' || sortField == 'plannedResolutionTime'){
       
        let sortingOrder = event?.sortOrder
        let tableData = this.dataTable?.data
  
       let modified = tableData.sort((a, b) => {
        const dataA = Number(a[sortField]); // Convert string to number
        const dataB = Number(b[sortField]); // Convert string to number
    
        return sortingOrder === 1 ? dataA - dataB : dataB - dataA;
        });
  
        this.dataTable['data'] = modified
       
       
    }else if (sortField =='resolvedOn'){}
    else{
      this.getFilterTicket(event?.first, event?.rows,this.searchText,sortField,sortOrder)
    }
    
    
  }

  customizedTableSearch(event:any){
     this.searchText = event
    this.getFilterTicket(0,50,event) 

  }
}
