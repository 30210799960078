<ul class="timeline">
    <ng-container *ngFor="let timeline of verticalTimeline">
    <li *ngIf="timeline?.viewDashboard">
    
        <!-- {{ timeline | json}} -->
        <span class="justify-space-between p-grid">
            <span class="p-col" style="padding: 0 1%;">
                <span *ngIf="timeline.createdAt" class="date">{{timeline.createdAt | date: 'EEE, dd MMM yyyy h:mm a'}}
                </span>
                <a *ngIf="timeline['commentBy'] && timeline['commentBy']['fullname']; else externalTicket">
                    {{ timeline['commentBy']['fullname'] }}&nbsp;
                </a>
                <ng-template #externalTicket>
                    <a>External Ticket</a>
                </ng-template>

                <a *ngIf="!timeline['commentBy'] && timeline['userType']">
                    <span *ngIf="(timeline['userType'] | lowercase ) == 'customer'"> {{timeline['customerName'] || ''}}
                    </span>
                    <span *ngIf="(timeline['userType'] | lowercase ) == 'vendor'"> Doctor </span>
                    &nbsp;</a>

                <span *ngIf="timeline['ticket_status'] && timeline['ticket_status']['status']">
                    -
                    <span class="custom-chip"
                        [ngStyle]="{'backgroundColor': (timeline['commentBy']||timeline['ticket_status']['status']['status']=='IncomingEmail') ? timeline['ticket_status']['status']['colorCode'] : ( timeline['userType'] && (timeline['userType'] | lowercase) == 'customer') ? '#6D214F' : timeline['ticket_status']['status']['colorCode'] ? timeline['ticket_status']['status']['colorCode'] : '#2C3A47' }">
                        {{timeline['ticket_status']['status']['status']}}</span>
                </span>

                <!-- <span *ngIf="timeline['ticket_status'] && timeline['ticket_status']['status']"> -
                    {{timeline['ticket_status']['status']['labelMessage'] || '' }}</span> -->
                <!-- <span *ngIf="timeline.commentTo"> - {{timeline.commentTo}}</span> -->
                <span *ngIf="timeline.watchlistMailSend ==  true"> - <span
                        [ngStyle]="{'backgroundColor': '#0075ff', 'color': '#fff', 'padding': '1px 5px' }">Email
                        Sent to Watchlist</span></span>
                <!-- Multiple attachments added -->

                <!--<a *ngIf="timeline.attachment && timeline.attachment.url" [href]="linkBaseUrl + timeline.attachment.url"
                    target="_blank" style="margin: 0 2px 0 10px;"><i class="fas fa-paperclip ancorLink"></i></a>
            
                <a *ngIf="timeline.attachment && timeline.attachment.filename"
                    [href]="linkBaseUrl + timeline.attachment.url" target="_blank"
                    class="ancorLink">{{timeline.attachment.filename |
                    slice:19:50}}{{timeline.attachment.filename.length>50? '...':''}}</a> -->
            </span>
            <span class="p-col" class="display-adj">
                <!-- <div class="p-grid">
                    <div class="p-col-12" style="padding: 0;">
                        <span  *ngIf="timeline['email']" class="t-checkbox">
                            <input type="checkbox" value="true" checked="true" disabled="true"
                                name="Email">
                            <label for="Email"> Email</label>
                        </span>&nbsp;
                        <span  *ngIf="timeline['sms']"  class="t-checkbox">
                            <input type="checkbox" value="true" checked="true" disabled="true" name="SMS">
                            <label for="SMS"> SMS</label>
                        </span> 
                    </div>
                    <div class="p-col-12" style="padding: 0;">
                        <div *ngIf="timeline['ticket_status'] && timeline['ticket_status']['status']" class="status-box"
                        [ngStyle]="{'backgroundColor': timeline['ticket_status']['status']['colorCode'] }">
                        {{timeline['ticket_status']['status']['status']}}</div>
                    </div>
                </div> -->

                <span class="t-checkbox">

                    <div *ngIf="timeline['email']" class="custom-chip" [ngStyle]="{'backgroundColor': '#0075ff' }">
                        Email Sent</div>
                </span>
                <span class="t-checkbox">
                    <div *ngIf="timeline['sms']" class="custom-chip" [ngStyle]="{'backgroundColor': '#ff6b81' }">
                        SMS Sent</div>
                    <!-- <input type="checkbox" [value]="timeline['sms']" [checked]="timeline['sms']" disabled="true" name="SMS">
                    <label for="SMS"> SMS</label> -->
                </span>

            </span>
        </span>
        <!-- <a href="#" class="float-right">21 March, 2014</a> -->
        <div *ngIf="timeline['comment']" class="innerHtmlContent innerHtmlStyle" [innerHtml]="timeline.comment"></div>
    
        <div *ngIf="timeline?.attachments?.length" class="">
            <!-- <span>{{'Attachment(s) :'}}</span> -->
        <ul class="p-pl-3">
            <li *ngFor="let file of timeline?.attachments" class="p-mb-1">
            <a [href]="linkBaseUrl + file?.url" class="file-container-wrap">
            <span class="filename-wrap p-mr-1">{{file?.filename}}</span>
            <i class="fas fa-paperclip ancorLink"></i>
            </a>
        </li>
        </ul>
        </div>
    </li>
    </ng-container>

</ul>