import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { Subscription } from 'rxjs';
import {
  IDialog,
  EDialogSize,
} from '../../../models/modules/shared/dialog.model';
import { environment } from '../../../../environments/environment';
import { AssetsComponent } from '../assets/assets.component';
import { DataService } from 'src/app/services/data.service';
import { Permission } from '../../../models/modules/shared/sidebar.model';

@Component({
  selector: 'sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.css'],
})
export class SidebarNavigationComponent implements OnInit {
  @Output() logout = new EventEmitter();
  @ViewChild('assetsComponent') assetsComponent: AssetsComponent;
  isFloatMenuVisible = false;
  isArrowRotated = false;

  getUserDetails$: Subscription;
  getCount$: Subscription;
  getTicketStatusList$: Subscription;
  assetViewed: boolean = false;
  taskViewed: boolean = false;
  showTask: boolean = false;
  showAsset: boolean = false;
  showDashboard: boolean = false;
  showConfiguration: boolean = false;
  showTicket: boolean = false;
  showKnowledgeBase: boolean = false;
  showAssetManagement: boolean = false;
  showIncident: boolean = false;
  showRoles: boolean = false;
  showProblem: boolean = false;
  showAnnouncement: boolean = false;
  showApprove: boolean = false;
  showAssetMaster: boolean = false;
  showAssetType: boolean = false;
  showAssignmentGrp: boolean = false;
  showAssignmentSubGrp: boolean = false;
  showContactInfo: boolean = false;
  showDepartment: boolean = false;
  showFaq: boolean = false;
  showOrganisation: boolean = false;
  showSlaModel: boolean = false;
  showSupportLevel: boolean = false;
  showUser: boolean = false;
  showReport: boolean = false;
  showHealthSnapshot: boolean = false;
  showFeedback: boolean = false;
  showActivityLog: boolean = false;
  showslaEvent: boolean = false;
  showSettings: boolean = false;
  showTicketRecords: boolean = false;

  viewDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.MEDIUM_1,
  };
  taskDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.MEDIUM_1,
  };

  //Calendar Dialogue
  calendarModalDialogue: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.LARGE,
  };

  assetsDialogue: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.LARGE_1,
  };
  viewProfileData = null;
  image_url;
  userConfig;
  _session;
  ticketType: any[] = [];
  selectedType: number | null = null;
  permissionsWithDescriptions: Permission[] = [];

  // ticketStatusCount;
  statusList;
  ticketStatusCount = [
    { key: 'new', colorCode: '#1289a6', value: 0, label: 'New' },
    {
      key: 'work in progress',
      colorCode: '#218c74',
      value: 0,
      label: 'Work In Progress',
    },
    { key: 'on hold', colorCode: '#ff7675', value: 0, label: 'On Hold' },
    { key: 'resolved', colorCode: '#D02F6B', value: 0, label: 'Resolved' },
    { key: 'cancelled', colorCode: '#6c5ce7', value: 0, label: 'Cancelled' },
    { key: 'closed', colorCode: '#636e72', value: 0, label: 'Closed' },
  ];

  placeholderImageUrl: string = '../../../../assets/images/photo.png';
  usersCompany = '';
  constructor(
    private router: Router,
    private userService: UserService,
    private apiService: ApiService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.checkForPermissions();
    this.getTicketType();
    this.getRequestType();
    if (this._session.profile_image) {
      let url = environment.api_preview_url + this._session.profile_image.url;
      url = url.replace(/([^:]\/)\/+/g, '$1');
      this.image_url = url;
    } else {
      this.image_url = this.placeholderImageUrl;
    }
    // this.getTicketStatusList();
    this.getTotalCount();
    this.getPermissionsWithDescriptions();
  }

  getRequestType() {
    this.apiService.get('request-type/getall').subscribe((response) => {
      this.dataService?.updateRequestType(response.data);
    });
  }

  getPermissionsWithDescriptions() {
    this.apiService.get('get-permission').subscribe((response) => {
      if (response.data) {
        this.permissionsWithDescriptions = response.data;
      }
    });
  }

  getDescription(name: string): string {
    const permission = this.permissionsWithDescriptions?.find(
      (permission) => permission.name === name
    );
    return permission ? permission.description : '';
  }
  // check for the user permission
  checkForPermissions() {
    const data = this.userService.getUserAccess();
    this._session = this.userService.getSession();
    this.userConfig = this._session['userConfig'];
    const uiPermission = this._session['role']['ui_permission'];
    const apiPermission = this._session['role']['api_permission'];
    this.usersCompany = this._session?.organization?.name;

    const permissions = JSON.parse(uiPermission);
    const userPermissions = uiPermission ? permissions : data;

    this.showTask = userPermissions?.task == true ? true : false;
    this.showAsset = userPermissions?.asset == true ? true : false;
    this.showDashboard = userPermissions?.dashboard == true ? true : false;
    this.showTicket = userPermissions?.ticket == true ? true : false;
    this.showKnowledgeBase =
      userPermissions?.knowledgebase == true ? true : false;
    this.showAssetManagement =
      userPermissions?.assetManagement == true ? true : false;
    this.showIncident = userPermissions?.incident == true ? true : false;
    this.showRoles = userPermissions?.role == true ? true : false;
    this.showProblem = userPermissions?.problem == true ? true : false;
    this.showAnnouncement =
      userPermissions?.announcement == true ? true : false;
    this.showApprove = userPermissions?.approve == true ? true : false;
    this.showAssetMaster = userPermissions?.assetMaster == true ? true : false;
    this.showAssetType = userPermissions?.assetType == true ? true : false;
    this.showAssignmentGrp =
      userPermissions?.supportGroup == true ? true : false;
    this.showAssignmentSubGrp =
      userPermissions?.serviceType == true ? true : false;
    this.showContactInfo = userPermissions?.contactInfo == true ? true : false;
    this.showDepartment = userPermissions?.department == true ? true : false;
    this.showFaq = userPermissions?.faq == true ? true : false;
    this.showOrganisation =
      userPermissions?.organization == true ? true : false;
    this.showSlaModel = userPermissions?.slaModel == true ? true : false;
    this.showSupportLevel =
      userPermissions?.supportLevel == true ? true : false;
    this.showUser = userPermissions?.user == true ? true : false;
    this.showReport = userPermissions?.reports == true ? true : false;
    this.showHealthSnapshot =
      userPermissions?.healthSnapshot == true ? true : false;
    this.showConfiguration =
      userPermissions?.configuration == true ? true : false;
    this.showFeedback = userPermissions?.feedback == true ? true : false;
    this.showActivityLog = userPermissions?.activityLog == true ? true : false;
    this.showslaEvent = userPermissions?.slaEvent == true ? true : false;
    this.showSettings = userPermissions?.settings == true ? true : false;
    this.showTicketRecords = userPermissions?.ticketRecords == true ? true : false;
  }

  toggleFloatMenu() {
    this.isFloatMenuVisible = !this.isFloatMenuVisible;
    this.isArrowRotated = !this.isArrowRotated;
  }

  onselectMenu(menu, submenu?) {
    if (submenu) document.getElementById(submenu).classList.toggle('show');

    let x = document.getElementById('SideNav').querySelectorAll('a, button');
    x.forEach((_x) => _x.classList.remove('active'));
    document.getElementById(menu).classList.toggle('active');
  }

  navigate(navigationLink) {
    this.router.navigate([navigationLink]);
  }

  openAssets() {
    this.assetsDialogue.display = true;
    this.assetViewed = true;
  }

  openTasks() {
    this.taskDialog.display = true;
    this.taskViewed = true;
  }

  getUserDetails() {
    this.getUserDetails$ = this.apiService
      .get(`get-user-by-id/${this._session['id']}`)
      .subscribe((response) => {
        if (!response.success) return;

        this.viewProfileData = response.data;

        this.viewDialog.display = true;
      });
  }

  getTotalCount() {
    this.getCount$ = this.apiService.get('get-tickets-count').subscribe((e) => {
      if (e.status && e.data) {
        let _ticketStatusCount = e.data;

        this.ticketStatusCount = this.ticketStatusCount.map((x) => {
          if (_ticketStatusCount.hasOwnProperty(x.key)) {
            x.value = _ticketStatusCount[x.key];
          }
          return x;
        });
      } else {
        return;
      }
    });
  }

  // Get the ticket status list
  getTicketStatusList() {
    this.getTicketStatusList$ = this.apiService
      .get(`get-ticket-status-master?type=dashboard`)
      .subscribe((response) => {
        const _statusList = response.data;
        this.ticketStatusCount = this.ticketStatusCount.map((x) => {
          console;
          let _found = _statusList.find(
            (y) => y.status.toUpperCase() == x.key.toUpperCase()
          );
          x.colorCode = _found['colorCode'];
          return x;
        });
      });
  }

  getStatusColor(status) {
    if (this.statusList.length < 1) return;
    const found = this.statusList.find(
      (x) => x.status.toUpperCase() == status.toUpperCase()
    );
    return found ? found['colorCode'] : '#337AB7';
  }

  closeViewProfile(e?) {
    this.viewDialog.display = false;
    this.viewProfileData = null;
    this.assetViewed = false;
    this.taskViewed = false;
  }

  handleImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.placeholderImageUrl;
  }

  _logout() {
    this.logout.emit(true);
  }

  getTicketType() {
    this.dataService.requestTypes$.subscribe((types) => {
      this.ticketType = types;
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.getUserDetails$) this.getUserDetails$.unsubscribe();
    if (this.getCount$) this.getCount$.unsubscribe();
  }

  openCalendar() {
    this.calendarModalDialogue.display = true;
  }

  onselectTicket(id: number) {
    this.selectedType = id;
    this.dataService.updateTicketType(id);
  }
}
