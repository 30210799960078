import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-lead-profile',
  templateUrl: './lead-profile.component.html',
  styleUrls: ['./lead-profile.component.css'],
})
export class LeadProfileComponent implements OnInit {
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {}

  uploadFileName: string = '';
  uploadedId = null;

  onFileChange(fileInput) {
    const file = fileInput.target.files[0];
    if (file) {
      this.uploadFileName = file.name;
      const formData = new FormData();
      formData.append('file', file);

      this.apiService.post('upload-file', formData).subscribe((res) => {
        if (!res.status) return;

        this.uploadedId = res['files']['id'];
      });
    } else {
      this.uploadFileName = '';
      this.uploadedId = null;
    }
  }
}
