import { Component, Input, OnInit } from '@angular/core';
import { IPanel } from 'src/app/models/modules/shared/panel.model';

@Component({
  selector: 'app-asset-management-table',
  templateUrl: './asset-management-table.component.html',
  styleUrls: ['./asset-management-table.component.css']
})
export class AssetManagementTableComponent implements OnInit {


  @Input() tableData: string = 'Header';

  panelConfig: IPanel = {
    showHeader: true,
  };
  constructor() { }

  ngOnInit(): void {
  }

}
